import React, { useState, useEffect } from "react";
import { signUp, confirmSignUp, autoSignIn } from "aws-amplify/auth";
import "./signUp.css";
import { passwordValidator, emailValidator } from "../../utils/validation";
import type { TUser, TLoginData } from "../DataTypes";
import { getToken } from "../../utils/authToken";
import { Link, useNavigate } from "react-router-dom";
import { subtle } from "crypto";
import { MAX_AUTHOR_INPUT } from "../../utils/constants";
import SyrupLoader from "../../components/common/syrupLoader/SyrupLoader";
import { getRecaptchaScore } from "../../utils/reCaptcha";
import { getApiEndpoint } from "../../api/endpoints";

const PasswordValidatorIcons = (props: any) => {
  return (
    <div className="validator-icons">
      <i
        hidden={passwordValidator(props.value, props.condition)}
        className="bi bi-x-circle"
      ></i>
      <i
        hidden={!passwordValidator(props.value, props.condition)}
        className={`bi bi-check2-circle${
          passwordValidator(props.value, props.condition) ? " valid" : ""
        }`}
      ></i>
    </div>
  );
};
const PasswordHelp = (props: any) => {
  return (
    <div id="passwordHelp" className="password-help">
      <div>
        <PasswordValidatorIcons condition={1} value={props.value} />
        <span> minimum 8 characters</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={2} value={props.value} />
        <span> at least one upper and lower character</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={3} value={props.value} />
        <span> at least one special character</span>
      </div>
    </div>
  );
};
interface Props {
  user: TUser;
  auth: boolean;
  darkmode: boolean;
  setDarkmode: any;
  setAuth: any;
  setUser: any;
}
const SignUp: any = (props: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    password: "",
    termsAndConditionsCheckbox: false,
    confirmationCode: "",
  });
  const [formError, setFormError] = useState({
    displayName: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false as boolean);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      termsAndConditionsCheckbox: !formData.termsAndConditionsCheckbox,
    });
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitConfirmation = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const username = formData.email;
    const confirmationCode = formData.confirmationCode;
    console.log("handleSubmitConfirmation: code =", formData.confirmationCode);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });
      console.log("handleSubmitConfirmation: nextStep = ", nextStep);
      const signInOutput = await autoSignIn();
      console.log("handleSubmitConfirmation: signInOutput = ", signInOutput);
      // ToDo:
      // 1 get token
      const authToken = await getToken();

      // 2 set user dipsplay name with post
      const postData = { display_name: formData.displayName };
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };

        const response = await fetch("https://api.idealpancakes.com/profile", {
          method: "POST",
          headers,
          body: JSON.stringify(postData),
        });

        const status = response.status;

        if (status === 200) {
          // move on
          // 3 set user data on front-end
          props.setUser({
            ...props.user,
            username: username,
            _token: authToken,
            author: formData.displayName,
          });
          props.setAuth(true);
          // 4 navigate to profile page
          navigate("/profile");
        }
      } catch (error) {
        console.error("Error making POST request:", error);
      }
    } catch (error) {
      console.log("error confirming sign up", error);
      setErrorSubmission(true);
    }
  };

  const displayNameCheck = async () => {
    try {
      const headers = {
        "Content-Type": "application/json", // Adjust content type if needed
      };

      const response = await fetch(
        getApiEndpoint("profile") + "/" + formData.displayName,
        {
          headers,
        }
      );
      // const result = await response.json();
      const status = await response.status;
      if (status === 200) {
        console.warn("Author name is NOT taken!");
        return true;
      } else if (status === 400) {
        console.warn("Display name IS taken!");
        setFormError({
          ...formError,
          displayName: "Sorry, that display name is already taken.",
        });
        setLoading(false);
        return false;
      } else {
        setErrorMsg("Oops, something went wrong!");
        setLoading(false);
        return false;
      }
      // check for redirect here for debug purposes
    } catch (error) {
      setLoading(false);
      setErrorMsg(String(error));
      console.error("get profile data, Error fetching data:", error);
      return false;
    }
  };
  // submit on sign up form
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMsg("");
    // do validation first
    if (
      formData.termsAndConditionsCheckbox &&
      passwordValidator(formData.password, 0) &&
      emailValidator(formData.email) &&
      formData.displayName
    ) {
      console.log("passes validation!", formData);
      setLoading(true);
      // now do reCaptcha check
      const isHuman = await getRecaptchaScore("signup");
      if (isHuman) {
        // do name check formData.displayName
        const nameIsAvailable = await displayNameCheck();
        if (nameIsAvailable) {
          const username = formData.email;
          const password = formData.password;
          const email = formData.email;
          try {
            const { isSignUpComplete, userId, nextStep } = await signUp({
              username,
              password,
              options: {
                userAttributes: {
                  email,
                },
                autoSignIn: true,
              },
            });
            console.log(userId);
            console.log("handleSubmit: nextStep =", nextStep);
            // after success w/ backend - show confirmation message
            setLoading(false);
            setSuccessfulSubmission(true);
          } catch (error) {
            console.log("error signing up:", error);
            // ToDo: Show error message
            setErrorMsg(String(error));
            setLoading(false);
            setErrorSubmission(true);
          }
        }
      } else {
        setErrorSubmission(true);
        setLoading(false);
      }
    }
  };

  // show success msg and check for email confirmation after form submit
  if (successfulSubmission) {
    // success email confirmation message
    return (
      <div className="col-md-4 offset-md-4 mt-5 sign-up-container">
        <div className="welcome-message">
          <h2>Welcome to Ideal Pancakes!</h2>

          <p>
            Please check your email for a confirmation code and enter it below.
          </p>
          <p>
            Once confirmed, you can create and share your culinary creations
            with the world.
          </p>
          <form onSubmit={handleSubmitConfirmation}>
            <div className="my-4 confirmation-code">
              <label htmlFor="confirmationCode" className="form-label">
                Confirmation Code
              </label>
              <input
                type="text"
                id="confirmationCode"
                name="confirmationCode"
                value={formData.confirmationCode}
                onChange={handleInputChange}
                className="form-control"
              />

              <button type="submit" className="btn btn-primary">
                Confirm
              </button>
            </div>
          </form>
          <p className="my-5">Happy cooking!</p>
          <div className="text-center">
            <p
              className="btn"
              onClick={() => {
                setSuccessfulSubmission(false);
              }}
            >
              - go back -
            </p>
          </div>
        </div>
      </div>
    );
  } else if (errorSubmission) {
    return (
      <div className="col-md-6 offset-md-3 mt-5 sign-up-container">
        <p>Uh oh, something went wrong, please try again at a later time.</p>
        <p>{errorMsg}</p>
      </div>
    );
  } else {
    // show sign up form
    return (
      <div className="col-md-4 offset-md-4 sign-up-container">
        <SyrupLoader loading={loading} />
        <div className="" hidden={loading}>
          <h2>Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                display name
              </label>
              <input
                type="displayName"
                id="displayName"
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
                className={`form-control${
                  formData.displayName ? " valid" : " invalid"
                }`}
                maxLength={MAX_AUTHOR_INPUT}
              />
              <div
                hidden={formData.displayName.length !== MAX_AUTHOR_INPUT}
                className="form-text"
              >
                character limit - {MAX_AUTHOR_INPUT}
              </div>
              <div
                hidden={formError.displayName === ""}
                className="text-danger"
              >
                {formError.displayName}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`form-control${
                  emailValidator(formData.email) ? " valid" : " invalid"
                }`}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={`form-control${
                  passwordValidator(formData.password, 0)
                    ? " valid"
                    : " invalid"
                }`}
              />
              <PasswordHelp value={formData.password} />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsAndConditionsCheckbox"
                  checked={formData.termsAndConditionsCheckbox}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="termsAndConditionsCheckbox"
                >
                  I agree to the{" "}
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </label>
              </div>
            </div>

            <div className="text-center">
              <p>Dont worry, we wont send you any unnecessary emails.</p>

              {/* reCAPTCHA widget
              <div id="your-recaptcha-element-id"></div> */}
              {/* reCaptcha - required to display in workflow */}
              <div className="text-muted recaptcha-text m-1">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </div>
              {/* END reCaptcha - required to display in workflow */}
              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>

              <p className="text-danger">{errorMsg}</p>

              <div className="mt-4">
                Already have an account? Sign in <Link to="/profile">here</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default SignUp;
