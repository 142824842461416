import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import "./contactUs.css";

import { getApiEndpoint } from "../../api/endpoints";
import SyrupLoader from "../../components/common/syrupLoader/SyrupLoader";
import { MAX_LG_INPUT } from "../../utils/constants";

interface ContactUsState {
  name: string;
  email: string;
  message: string;
}

const ContactUsPage = (props: any) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false as boolean);

  useEffect(() => {
    if (props.user.username && props.user.author) {
      setFormData({
        ...formData,
        email: props.user.username,
        name: props.user.author,
      } as Pick<ContactUsState, keyof ContactUsState>);
    }
  }, [props.user]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value } as Pick<
      ContactUsState,
      keyof ContactUsState
    >);
  };

  const handleSubmit = async (event: FormEvent) => {
    console.log("Form data submitted:", formData);
    setLoading(true);
    event.preventDefault();
    // Perform form submission
    try {
      // ToDo: Perform the server request here
      const urlPath = getApiEndpoint("contact");
      const response = await fetch(urlPath, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setLoading(false);
        setShowError(true);
        throw new Error("Server rejected the request");
      }

      // Success
      setLoading(false);
      setShowSuccess(true);
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      setLoading(false);
      // Error
      setShowError(true);
      // clear form
      setFormData({ name: "", email: "", message: "" });
    }
  };

  const handleGoBack = () => {
    setShowError(false);
    setShowSuccess(false);
  };
  if (loading) {
    return (
      <div>
        <SyrupLoader loading={loading} />;
      </div>
    );
  } else if (showSuccess) {
    return (
      <div className="contact-us">
        <div className="text-center">
          <h2>Message Sent Successfully!</h2>
          <p>Thank you for contacting us!</p>
        </div>
        <p className="m-1 text-center go-back-btn" onClick={handleGoBack}>
          - go back -
        </p>
      </div>
    );
  } else if (showError) {
    return (
      <div className="contact-us">
        <div className="text-center">
          <h2>Oops, something went wrong!</h2>
          <p>
            We apologize, but there was an issue while processing your message.
          </p>
          <p>Please try again later or contact us through alternative means.</p>
        </div>
        <p className="m-1 text-center go-back-btn" onClick={handleGoBack}>
          - go back -
        </p>
      </div>
    );
  } else {
    return (
      <div className="col-md-6 offset-md-3">
        <div className="contact-us">
          <h1>Contact Us</h1>
          <p>
            Ideal Pancakes is currently in its early stages of existence, and
            you may encounter occasional bugs while navigating the app. Your
            patience and understanding during these instances are greatly
            appreciated. If you do come across any issues or have suggestions
            for improvement, please take a moment to fill out the contact form
            below.
          </p>
          <p>Your feedback helps us be better!</p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                maxLength={MAX_LG_INPUT}
              ></textarea>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default ContactUsPage;
