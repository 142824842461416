export function passwordValidator(string: string, condition?: number) {
  // at least 8 characters
  const condition1 = /^.{8,}$/;
  // at least one upper and one lower
  const condition2 = /(?=.*[a-z])(?=.*[A-Z])/;
  // at least one special char
  const condition3 = /[\W_]+/;

  if (!condition || condition === 0) {
    return (
      condition1.test(string) &&
      condition2.test(string) &&
      condition3.test(string)
    );
  } else if (condition === 1) {
    return condition1.test(string);
  } else if (condition === 2) {
    return condition2.test(string);
  } else if (condition === 3) {
    return condition3.test(string);
  }
}

export function emailValidator(string: string) {
  const condition = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return condition.test(string);
}
