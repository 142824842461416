import React, { useEffect, useState, useRef } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import type { TUser, TNavData } from "../DataTypes";
import { signOut } from "aws-amplify/auth";

interface TheProps {
  user: TUser;
  auth: boolean;
  setAuth: any;
  navData: TNavData;
  setUser: any;
}

const Navbar = (props: TheProps) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);
  const iconRef = useRef<any>(null);

  const handleLogout = async () => {
    props.setAuth((item: any) => false);
    props.setUser(() => {
      return {};
    });

    try {
      await signOut();
    } catch (error) {
      console.log("handleLogout: error =", error);
    }
    // Reload the browser window - this kicks them out of a page if login is required
    window.location.reload();
  };
  // Function to toggle the menu's visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking away
  const handleClickOutside = (event: any) => {
    if (
      isMenuOpen &&
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current !== event.target &&
      iconRef.current !== event.target
    ) {
      setIsMenuOpen(false);
    }
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleNavigate = (path: string) => {
    const previousPathname = window.location.pathname + window.location.search; // Get current pathname
    // Update state with the current pathname
    navigate(path, { state: { from: previousPathname } });
  };

  const BigScreenProfileMenu = () => {
    return (
      <div
        className="navbar-btn col-xl-3 col-md-4 offset-xl-6 offset-md-4 nav-item large-screen-profile-menu"
        hidden={!props.auth}
      >
        <div className="float-end me-3">
          <a
            className="btn nav-btn"
            onClick={() => {
              handleNavigate("/profile");
            }}
          >
            <i className="bi bi-person-circle"></i>
            <div className="username-div" title={props.user.username}>
              {props.user.username}
            </div>
          </a>
          <button
            type="button"
            className="btn dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                className="dropdown-item"
                to={`/?query="my recipes"`}
                reloadDocument
              >
                My Recipes
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={`/?query="liked recipes"`}
                reloadDocument
              >
                Liked Recipes
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  const LilScreenProfileMenu = () => {
    return (
      <div className="lil-screen-profile-menu" hidden={!props.auth}>
        <div className="navbar-btn col-md-2 nav-item">
          <Link className="btn nav-btn" to={`/profile`}>
            <i className="bi bi-person-circle"></i>
            {props.user.username}
          </Link>
        </div>
        <div className="navbar-btn col-md-2 nav-item">
          <Link
            className="btn nav-btn"
            to={`/?query="my recipes"`}
            reloadDocument
          >
            My Recipes
          </Link>
        </div>
        <div className="navbar-btn col-md-2 nav-item">
          <Link
            className="btn nav-btn"
            to={`/?query="liked recipes"`}
            reloadDocument
          >
            Liked Recipes
          </Link>
        </div>
        <div className="navbar-btn col-md-2 nav-item ">
          <a className="btn nav-btn" href="#" onClick={handleLogout}>
            Logout
          </a>
        </div>
      </div>
    );
  };

  return (
    <nav className="navbar navbar-expand-md">
      <div className="nav-container d-flex flex-row align-items-center">
        <button
          className="navbar-hamburger"
          type="button"
          // data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleMenu}
          ref={buttonRef}
        >
          <i ref={iconRef} className="bi bi-list navbar-hamburger-icon"></i>
        </button>
        <div className="nav-logo-box">
          <Link className="navbar-brand header-logo" to={`/`}>
            IDEAL PANCAKES
          </Link>
        </div>
        <div
          className={`navbar-collapse ${isMenuOpen ? "show-menu" : ""}`}
          id="navbarSupportedContent"
          ref={menuRef}
          onClick={closeMenu}
        >
          <div className="col-xl-3 col-md-4 navbar-btn nav-item">
            <Link
              className="create-recipe btn nav-btn"
              to={`/recipe/-1/edit`}
              reloadDocument
            >
              CREATE A RECIPE
            </Link>
          </div>
          <div className="col-xl-6 col-md-4" hidden={props.auth}></div>

          <div className="navbar-btn col-md-2 nav-item" hidden={props.auth}>
            <a
              className="btn nav-btn profile-btn"
              onClick={() => {
                handleNavigate("/profile");
              }}
            >
              <i className="bi bi-person-circle"></i>
              {props.auth ? props.user.username : "LOG IN"}
            </a>
          </div>

          <div className="navbar-btn col-md-2 nav-item" hidden={props.auth}>
            <Link className="btn nav-btn sign-up" to={`/sign-up`}>
              SIGN UP
            </Link>
          </div>
          <LilScreenProfileMenu />
          <BigScreenProfileMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
