import React from "react";
import "./Recipe.css";
import { useNavigate } from "react-router-dom";

interface TheProps {
  title: string;
  id: string;
  author: string;
  img: string;
}

const RecipePreview = (props: TheProps) => {
  const navigate = useNavigate();
  const handleClick = (recipeId: string) => {
    navigate("/recipe/" + recipeId);
  };
  return (
    <div
      className="recipe-card row"
      onClick={() => {
        handleClick(props.id);
      }}
    >
      <div className="col-md-6 one">
        <div className="recipe-info">
          <div className="recipe-title">{props.title}</div>
          <div className="recipe-author">by {props.author}</div>
        </div>
      </div>
      <div className="col-md-6 recipe-photo two">
        <img
          alt="stack of pancakes"
          className="img-fluid recipe-img"
          src={props.img}
        />
      </div>
    </div>
  );
};

export default RecipePreview;
