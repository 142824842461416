import React from "react";
import { Link } from "react-router-dom";
import "./privacyPolicy.css";

const PrivacyPolicyPage = (props: any) => {
  return (
    <div className="col-md-10 offset-md-1 privacy-policy">
      <div className="row m-4">
        <h1>Ideal Pancakes Privacy Policy</h1>
        <div id="section-1" className="row">
          <div className="col-md-12">
            <p>
              Welcome to Ideal Pancakes, a website owned and operated by
              Manifest Matter LLC. As part of our commitment to transparency and
              legal compliance, we want to inform you that all content,
              services, and intellectual property on Ideal Pancakes fall under
              the legal ownership of Manifest Matter LLC. Manifest Matter LLC
              (hereafter “Company,” “We,” “Us” or “Our”) is committed to
              protecting and respecting your privacy. This Privacy Policy
              describes Our practices for collecting, using, maintaining,
              protecting, and disclosing your personal information (“Personal
              Data”). Where terms are bolded and/or capitalized, other than for
              proper grammar, such terms have the meanings provided in the
              Definitions Section of our{" "}
              <Link
                to="/terms-and-conditions"
                aria-label="Read more about ideal pancakes terms and conditions"
              >
                Terms and Conditions
              </Link>
              . Our Online Terms and software End User license agreement (EULA),
              if any, are incorporated within this Privacy Policy as if
              specifically written herein (all collectively referenced as our
              “Terms”).
            </p>
            <p>
              Please read this Privacy Policy carefully to understand Our
              policies and practices regarding your Personal Data and how We
              will handle it. By accessing or Using Our Website(s) or Mobile
              Application(s), you agree to this Privacy Policy. This Privacy
              Policy may change from time to time in the Company’s sole
              discretion. Your continued Use of Our Website(s) or Mobile
              Application(s) after We make changes is deemed to be acceptance of
              those changes, so please check the Privacy Policy periodically for
              updates, found at{" "}
              <Link
                to="/privacy-policy"
                aria-label="A link to the ideal pancakes privacy policy"
              >
                www.idealpancakes.com/privacy-policy
              </Link>
              . If you are an End User, i.e., have accessed Our Website(s) or
              Mobile Application(s) and have a logon username and password, you
              will receive notification if We change this Privacy Policy when
              you next log into your account. The first time you visit Our
              Website you will be asked to read and accept this Privacy Policy.
              If you do not agree with this Privacy Policy, do not Use Our
              Website.
            </p>
          </div>
        </div>
        <div className="row">
          <h2>This Privacy Policy contains the following sections:</h2>
          <ul className="table-of-contents">
            <li>
              <a href="#section-1">Introduction</a>
            </li>
            <li>
              <a href="#section-2">Scope of Policy</a>
              <ul>
                <li>
                  <a href="#section-2.1">Consent</a>
                </li>
                <li>
                  <a href="#section-2.2">Use By Minors</a>
                </li>
                <li>
                  <a href="#section-2.3">
                    Sharing Personal Data: Unrelated Third Parties
                  </a>
                </li>
                <li>
                  <a href="#section-2.4">
                    Sharing Personal Data: Business Partners
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#section-3">Information We May Collect from You</a>
            </li>
            <li>
              <a href="#section-4">Security</a>
              <ul>
                <li>
                  <a href="#section-4.1">Where We Store Your Information</a>
                </li>
                <li>
                  <a href="#section-4.2">Passwords</a>
                </li>
                <li>
                  <a href="#section-4.3">Hackers</a>
                </li>
                <li>
                  <a href="#section-4.4">
                    Third-Party Websites & Communications
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#section-5">Advertising</a>
            </li>
            <li>
              <a href="#section-6">Social Media</a>
            </li>
            <li>
              <a href="#section-7">
                Privacy Risks Associated with Online Purchases and
                Communications
              </a>
            </li>
            <li>
              <a href="#section-8">Retention</a>
              <ul>
                <li>
                  <a href="#section-8.1">Commercial Requests for Information</a>
                </li>
                <li>
                  <a href="#section-8.2">End Users</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#section-9">Your Rights</a>
            </li>
            <li>
              <a href="#section-10">Transfer of Personal Data Outside Europe</a>
            </li>
            <li>
              <a href="#section-11">California Users</a>
            </li>
            <li>
              <a href="#section-12">Changes to Privacy Policy</a>
            </li>
            <li>
              <a href="#section-13">Complaints</a>
            </li>
            <li>
              <a href="#section-14">Contact</a>
            </li>
          </ul>
        </div>

        <div id="section-2" className="row mt-4">
          <div className="col-md-12">
            <h2>Scope of Policy</h2>
            <p>This Privacy Policy applies to:</p>
            <ul>
              <li>
                Anyone who Uses, visits, or otherwise accesses Our public
                Website(s), including, without limitation,{" "}
                <Link to="/" aria-label="Link to our home page">
                  www.idealpancakes.com
                </Link>{" "}
                or any of Our Affiliates’ Website(s),
              </li>
              <li>
                Anyone who Uses, downloads, or otherwise accesses Our Mobile
                Application(s),
              </li>
              <li>Anyone who requests information by e-mail or telephone,</li>
              <li>
                Anyone who provides End User Content or uploads Personal Data to
                Our Website or otherwise sends such information to Us,
              </li>
              <li>Anyone who applies for a job with Us,</li>
              <li>
                Anyone who purchases, downloads, licenses, or Uses Our Website
                through Us or Our resellers, or Our Affiliates,
              </li>
              <li>Anyone who participates in any survey or contest,</li>
              <li>
                Users of any of third-party services provided by Us or Our
                Affiliates, retailers, or Business Partners, or
              </li>
              <li>
                End Users of any of Our Websites accessible through any of Our
                downloadable or Internet-based programs or applications or
                through any of Our Affiliates, retailers, or Business Partners.
              </li>
            </ul>
            <p>This policy does not apply to information collected by:</p>
            <ul>
              <li>
                The Company offline or through any means other than through Our
                Website(s) or Mobile Application(s); or
              </li>
              <li>
                Any third party, including without limitation, Our Affiliates,
                subsidiaries, or advertising that may link to or be accessible
                from or on Our Website(s).
              </li>
            </ul>
            <p>
              The legal definition of Personal Data varies by location. If you
              are located in the U.S., the legal definition in Colorado shall
              apply.
            </p>
            <div className="p-4">
              The Colorado Privacy Act (CPA), effective July 1, 2023, defines
              &quot;Personal Data&quot; as &quot;information that is linked or
              reasonably linkable to an identified or identifiable
              individual.&quot; It does not include de-identified data or
              publicly available information. &quot;[P]ublicly available
              information&quot; means information that is lawfully made
              available from federal, state, or local government records and
              information that a controller has a reasonable basis to believe
              the consumer has lawfully made available to the general public.
              Colo. Rev. Stat. § 6-1-1303.
            </div>
            <p>
              If you are located outside the U.S., only the legal definition
              that applies in your physical location will apply to you under
              this Privacy Policy. Nothing in this Privacy Policy is meant to
              alter the applicable Law governing Our Terms.
            </p>
            <div className="p-4">
              <div id="section-2.1">
                <h3>Consent</h3>
                <p>
                  You are never required to provide Us with the types of
                  information covered by this Privacy Policy. Without that
                  information, however, you may not be able to access or Use Our
                  Website(s) or Mobile Application(s). For End Users located
                  outside the European Union/European Economic Area, by
                  accessing Our Website, and/or providing information to Us, you
                  do so voluntarily and are expressly opting-in and
                  unambiguously consenting to the collection, use, retention,
                  disclosure, and transfer, including cross-border transfer, of
                  your Personal Data as explained in this Privacy Policy. For
                  individuals located within the European Union/European
                  Economic Area, you will be asked to ‘opt in’ to this Privacy
                  Policy and Our collection, use, retention, disclosure, and
                  transfer of Personal Data prior to accessing Our Website. In
                  some instances, your employer may ‘opt in’ on your behalf by
                  separate agreement.{" "}
                </p>
              </div>
              <div id="section-2.2">
                <h3>Use By Minors</h3>
                <p>
                  Our Website(s) and Mobile Application are not intended for
                  children under the age of eighteen (18). We do not knowingly
                  collect Personal Data from anyone under the age of majority in
                  your location. Our Websites are directed at people who are at
                  least the age of majority where they live and may not be Used
                  by minors without parental or guardian consent. If you believe
                  your child has provided Us with Personal Data or accessed Our
                  Website(s) or Mobile Application(s) wrongfully, please contact
                  Us by email at info@manifestmatter.com and We will promptly
                  delete such account and/or Personal Data. California residents
                  under the age of sixteen (16) may have additional rights
                  regarding the collection and sale of their information. Please
                  see <a href="#section-15">California Users</a> in this policy
                  for more information.
                </p>
              </div>
              <div id="section-2.3">
                <h3>Sharing Personal Data: Unrelated Third Parties</h3>
                <p>
                  We do not share, sell, or rent Personal Data to or with
                  unrelated third parties without providing you a choice.
                  Personal Data that you provide to Us while using our
                  Website(s) or Mobile Application(s) may be used to contact you
                  for the provision of products, services, promotional offers,
                  marketing programs, or other communications from Us, or Our
                  Business Partners. Such Business Partners may include, by way
                  of example only, Our vendors, contractors, Affiliates,
                  licensors, licensees (other than you), and payment processors.
                  If you are accessing or using Our Website as an employee,
                  volunteer, or contractor, please be aware that your employer
                  may have authorized Us to use and/or share your Personal Data.
                </p>
              </div>
              <div id="section-2.4">
                <h3>Sharing Personal Data: Business Partners</h3>
                <p>
                  Our Business Partners who have access to your Personal Data in
                  connection with providing services to Us or you are required
                  to keep your Personal Data confidential and are not permitted
                  to use this information for any other purpose other than to
                  carry out the services they are performing or to market Our
                  Website(s) or Mobile Application(s), products, and/or
                  services, or their services to you, as long as you have the
                  option to opt out of that communication. Except for your
                  communications with payment processors, only your email and
                  first and last name will be used by Business Partners for the
                  purpose of marketing products or services. When We make
                  Personal Data available to Our Business Partners, We will not
                  share with them any more information than is necessary; and We
                  will use reasonable efforts to ensure, by contract or
                  otherwise, that they use your Personal Data in a manner that
                  is consistent with this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="section-3" className="row mt-4">
          <div className="col-md-12">
            <h2>Information We May Collect from You</h2>
            <p>
              We automatically collect Personal Data when you access Our
              Website(s) or Mobile Application(s), or otherwise purchase a
              product or service from Our Website(s) or Mobile Application(s).
              The types of information We collect may include:
            </p>
            <ul>
              <li>
                Networking and device information, such as your type of browser,
                IP address, language settings, and the version of your operating
                system.
              </li>
              <li>
                Information regarding your activity on Our Website(s) and Mobile
                Application, such as access time, pages viewed, the website from
                which you accessed Our Site, and your Use of any hyperlinks on
                Our Site.
              </li>
              <li>
                Information regarding your purchases or transactions and any
                returns.
              </li>
              <li>
                Information collected by cookies, web beacons, and other
                tracking technologies, including Internet service provider
                (ISP), Mobile Advertising ID, media access control (MAC)
                address, or identifiers associated with such technologies.
              </li>
              <li>
                Location information in accordance with your device permissions.
              </li>
              <li>
                Once you purchase products or services on Our Website(s) or
                Mobile Application, We may collect your name, email, or postal
                address, geolocation data and IP addresses, credit card and
                billing address, and other information, such as and End User
                Content, you upload to the Website, including, your photograph,
                age, images, audio, video, uploaded files, biographic
                information, interests, and consumer preference characteristics.
              </li>
            </ul>
            <p>
              We may also collect data about you from third-party sources, e.g.,
            </p>
            <ul>
              <li>Information collected from Our Business Partners,</li>
              <li>
                Information collected from other End Users or referral sources,
              </li>
              <li>
                Information collected from Linked Accounts such as from
                third-party social media and communication services that you may
                Use to interact with Our Website(s) or Mobile Application, but
                We will only collect such information in accordance with the
                authorization and privacy settings you establish in those
                services.
              </li>
              <li>
                Information from unaffiliated service providers, including,
                without limitation and by way of example only, analytics
                companies, advertising networks, and consumer data resellers.
              </li>
            </ul>
            <p>
              We may also derive information or draw inferences about you based
              on the other types of data We collect. For example, We may infer
              your location based upon your IP address or that you are
              interested in purchasing a particular product or service based
              upon your browsing behavior on Our Website.
            </p>
            <p>
              We use your Personal Data to take necessary steps to respond to a
              request and/or to perform services requested. In addition, direct
              marketing is necessary for the legitimate business interests We
              are pursuing. You may unsubscribe from emails at any time by
              clicking on the “unsubscribe” link in the emails you receive.
            </p>
            <p>
              Your Personal Data may be available to Our employees, Affiliates,
              or Business Partners with a legitimate business need, such as Our
              marketing team, order fulfillment team, operations team, survey
              processors, contest judges, customer relationship management
              software providers (CRM), and payment processors.
            </p>
            <p>
              We do not request or require you to provide any other special
              categories of Personal Data including, for example, genetic data,
              biometric data, or information relating to your racial or ethnic
              origin, political opinions, religious beliefs, trade union
              membership, physical health, or sexual orientation. If We become
              aware of any such data having been submitted to Us, We will, where
              reasonably practicable, endeavor to delete it. We do not monitor
              communications between End Users, so if you have an issue with
              information you have uploaded, please contact Us at
              info@manifestmatter.com.
            </p>
            <p>
              Because of the nature of Our Website(s) or Mobile Application(s),
              We may possess backup information of your Personal Data that may
              include payment information and shipping addresses. If you cease
              to Use Our Website, We will anonymize, pseudonymize or otherwise
              de-identify the information so that it cannot be associated with
              you. You may contact Us to delete your Personal Data at
              info@manifestmatter.com.
            </p>
            <p>
              We will only disclose your Personal Data to law enforcement,
              government authorities, and/or legal counsel if required by
              applicable Law to: (a) protect Our, your, or others’ rights,
              privacy, safety, or property (including by making and defending
              legal claims); (b) enforce Our Terms; and (c) protect,
              investigate, and deter against fraudulent, harmful, unauthorized,
              unethical, or illegal activity.
            </p>
          </div>
        </div>
        <div id="section-4" className="row mt-4">
          <div className="col-md-12">
            <h2>Security</h2>
            <div className="p-4">
              <div id="section-4.1">
                <h3>Where We Store Your Information</h3>
                <p>
                  We use servers in the United States to process and store
                  Personal Data. In the future We may use servers located
                  elsewhere. In that event, We will update this Privacy Policy
                  and will promptly inform End Users of the update through a
                  notice appearing when an End User logs into the Website(s) or
                  Mobile application.
                </p>
                <p>
                  While no server can be completely secure, We make reasonable
                  efforts to ensure that these servers are kept in a secure,
                  locked environment with restricted access and We have on Our
                  own, and in conjunction with the third parties who operate the
                  servers, established physical, electronic, and procedural
                  safeguards to protect Personal Data.
                </p>
                <p>
                  Personal Data stored by Us may be accessed and processed by
                  staff operating outside your jurisdiction, who work either for
                  Us or for one of Our Business Partners. This staff may be
                  engaged in fulfilling your order or request, processing a
                  payment, storing information, and/or providing support
                  services to Us. We require these Business Partners to agree to
                  treat your Personal Data securely and in accordance with this
                  Privacy Policy and applicable Law.
                </p>
                <p>
                  We have implemented a variety of technical and organizational
                  measures to protect Personal Data from loss, misuse, unlawful
                  processing, unauthorized access, disclosure, copying,
                  alteration, and destruction. These include limiting access to
                  the databases to a limited number of authorized staff who are
                  required to maintain the information as confidential. Further,
                  access to the databases is password protected. We also have in
                  place audit logs, intrusion detection software, anti-virus or
                  malware protection, and system integrity tools to further
                  protect data stored on these databases.
                </p>
              </div>
              <div id="section-4.2">
                <h3>Passwords</h3>
                <p>
                  Where We have provided you (or where you have chosen) a
                  password that enables you to access certain portions of Our
                  Website(s) or Mobile Application, you are responsible for
                  keeping this password confidential. You are responsible in
                  protecting your account by not sharing your password with
                  anyone.
                </p>
              </div>
              <div id="section-4.3">
                <h3>Hackers</h3>
                <p>
                  While We make reasonable and industry-standard efforts to
                  ensure the integrity and security of Our network, Website(s),
                  Mobile Application, and systems, We cannot guarantee that such
                  security measures will prevent third-party “hackers” from
                  illegally obtaining information. We cannot be, and are not,
                  responsible for circumvention of any privacy settings or
                  security measures contained in Our Website(s) or Mobile
                  Application, including the illegal acts of third parties (such
                  as criminal hacking). We disclaim any and all liability for
                  disclosure of any information obtained due to errors in
                  transmission or the unauthorized acts of third parties as
                  discussed in greater detail in our{" "}
                  <Link
                    to="/terms-and-conditions"
                    aria-label="Link to our terms and conditions"
                  >
                    terms and conditions
                  </Link>
                  .
                </p>
                <p>
                  ANY TRANSMISSION OF PERSONAL DATA OR OTHER TYPES OF
                  INFORMATION THROUGH OUR WEBSITE(S) OR MOBILE APPLICATION IS AT
                  YOUR OWN RISK.
                </p>
              </div>
              <div id="section-4.4">
                <h3>Third-Party Websites & Communications</h3>
                <p>
                  Our Website(s) or Mobile Application may, from time to time,
                  contain links to and from the websites of third parties. We
                  only link to the home page of third-party content. If you
                  follow a link to any of these websites, please note that these
                  websites and any services that may be accessible through them
                  have their own privacy policies and that We do not accept any
                  responsibility or liability for these policies or for any
                  Personal Data that may be collected through these websites or
                  services. This includes any posting you may make on Our social
                  media page(s), which page(s) are controlled by such
                  third-party social media sites. We urge you to exercise care
                  when providing information to anyone and to check the policies
                  and terms of all third-party websites before you submit any
                  Personal Data.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="section-5" className="row mt-4">
          <div className="col-md-12">
            <h2>Advertising</h2>
            <p>
              We may use third-party advertising companies to serve ads when you
              visit Our Website(s) or Mobile Application. Such third-party
              companies may use information (not including your name, address,
              or e-mail address) about your visits to this and other websites to
              provide advertisements on sites, goods, and services that may be
              of interest to you. If you would like more information about this
              practice, and to know your choices about not having this
              information used by these companies, please review your rights at
              the Network{" "}
              <Link
                to="https://optout.networkadvertising.org/?c=1#!%2F"
                aria-label="Read more about your rights as they pertain to advertising"
              >
                Advertising Initiative
              </Link>
              .
            </p>
          </div>
        </div>
        <div id="section-6" className="row mt-4">
          <div className="col-md-12">
            <h2>Social Media</h2>
            <p>
              When you use features such as social networking, chat rooms, or
              forums, you should take precautions not to submit any Personal
              Data that you do not want to be seen, collected, or used by
              others. Our Website(s) or Mobile Application may include features
              from Linked Accounts such as social media websites. These features
              may collect your IP address, which page you are visiting, and may
              set a Cookie to enable the feature to function properly. Social
              media features are either hosted by a third party or hosted
              directly within Our Website. This Privacy Policy does not apply to
              these features. Your interactions with such features are governed
              by the privacy policy, terms, and other policies of the companies
              providing those features.
            </p>
          </div>
        </div>
        <div id="section-7" className="row mt-4">
          <div className="col-md-12">
            <h2>
              Privacy Risks Associated with Online Purchases and Communications
            </h2>
            <p>
              End Users may Use Our Website(s) or Mobile Application to
              communicate with Business Partners and certain other
              third-parties, which may include the purchase of third-party
              products and services.{" "}
            </p>
            <p>
              All Business Partners and third-parties that you have access to on
              Our Website(s) or Mobile Application will be approved by Us. We
              encourage you to review the privacy policies for each Business
              Partner or third-party you engage with through Our Website(s) or
              Mobile Application and the privacy policies of each website and
              application you visit and use in general.
            </p>
            <p>
              Business Partners and third-parties that are not listed on Our
              Website(s) or Mobile Application have not been approved by Us and
              therefore We cannot guarantee their identity or qualifications. We
              urge you to exercise caution when engaging with anyone outside of
              Our Website(s) or Mobile Application. There is no substitute for
              good judgment and proper research and due diligence.
            </p>
            <p>
              As examples only: Do not meet anyone in any private, secluded
              location. Do not give money to someone you do not know. Do not
              share your Personal Data with anyone you do not know. If you
              believe you are in any imminent danger, proceed to the closest
              police department or dial 9-1-1.
            </p>
          </div>
        </div>
        <div id="section-8" className="row mt-4">
          <div className="col-md-12">
            <h2>Retention</h2>
            <p>
              The amount of time We hold your Personal Data will vary but will
              not be kept for longer than is necessary for the purposes for
              which it is being processed. We will retain your Personal Data in
              accordance with the following criteria:
            </p>
            <div className="p-4">
              <div id="section-8.1">
                <h3>Commercial Requests for Information</h3>
                <p>
                  We will retain your Personal Data solely for the legitimate
                  business purpose of responding to your request or inquiry and
                  to communicate with you regarding the Use of Our Website(s) or
                  Mobile Application. You may opt out of such communications at
                  any time and you will be provided with that opportunity within
                  each communication.
                </p>
              </div>
              <div id="section-8.2">
                <h3>End Users</h3>
                <p>
                  We will retain your Personal Data indefinitely while you
                  maintain an End User login to Our Website(s) or Mobile
                  Application. When you cancel or otherwise discontinue the use
                  your End User login to Our Website(s) or Mobile Application,
                  We will retain your Personal Data for a reasonable period of
                  time in order to allow you to download any database
                  information, if any, stored therein. Thereafter, any Personal
                  Data will be anonymized or pseudonymized or otherwise
                  deidentified so that it cannot be connected with a particular
                  individual, entity, or IP address.
                </p>
              </div>
            </div>
            <p>
              If you provide Us with a username for online payments or credit
              card information, We, or Our Business Partners, such as payment
              processors, may maintain that information for as long as you
              maintain your End User login in order to accept or send payments.
              If you provide such information for one-time payments, We will
              delete such information as soon as the one-time transaction is
              completed.
            </p>
          </div>
        </div>
        <div id="section-9" className="row mt-4">
          <div className="col-md-12">
            <h2>Your Rights</h2>
            <p>
              You have certain rights in relation to the Personal Data we
              process. These rights to access, correct, amend, or delete
              Personal Data vary by location and only the rights available to
              you in your physical location will be enforceable by you under
              this Privacy Policy.
            </p>
            <p>
              Typically, and by way of example only, you have the following
              rights:
            </p>
            <ul>
              <li>
                The right to access your Personal Data unless access is subject
                to a legal exception.
              </li>
              <li>
                The right to have your Personal Data corrected if it is
                inaccurate or incomplete.
              </li>
              <li>The right to have your Personal Data not processed.</li>
              <li>
                The right to have your Personal Data erased if it is no longer
                necessary or required in relation to the purposes and there are
                no other overriding legitimate grounds for Us to continue
                processing it.
              </li>
              <li>
                The right to opt out if your Personal Data is to be used for a
                purpose that is materially different from the purpose(s) for
                which it was originally collected or you subsequently
                authorized.
              </li>
              <li>
                The right to complain to a supervisory authority if you think
                your rights have been infringed.
              </li>
              <li>
                You may also have the right to request that a business disclose
                the categories or details of Personal Data collected.
              </li>
            </ul>
            <p>
              To exercise your rights, you may make an inquiry by sending an
              email request directly to info@manifestmatter.com. In some cases,
              you may need to discuss deletion with your Linked Account
              administrator if you access our Website(s) or Mobile Application
              through your Linked Account.
            </p>
            <p>
              We will respond to your request in accordance with the Laws that
              apply to you. When you make your request, We will maintain your
              request and related Personal Data as We maintain other Personal
              Data in order to respond, after which time We will delete the
              Personal Data provided in the request. Access requests are free;
              however, We reserve the right to charge a reasonable fee to comply
              with your request when your request is unfounded or excessive.
            </p>
          </div>
        </div>
        <div id="section-10" className="row mt-4">
          <div className="col-md-12">
            <h2>Transfer of Personal Data Outside Europe</h2>
            <p>
              In the case of individuals located in countries where the General
              Data Protection Regulation (“GDPR”) applies, to the extent We
              transfer Personal Data to Our office, or Our Business Partners’
              offices, in the U.S., We make efforts to comply with the GDPR. To
              the extent We transfer Personal Data to Canada, for the purposes
              set out in this Privacy Policy, We rely on the fact that Canada
              has been designated by the European Commission as a country that
              offers an adequate level of protection for Personal Data.
            </p>
            <p>
              Where We transfer Personal Data relating to individuals located in
              the EU or the European Economic Area to third parties, We will
              endeavor to enter into Data Transfer Agreements based on the EU
              model clauses. We will endeavor provide reasonable,
              industry-standard technical and organizational measures to protect
              Personal Data from accidental or unlawful destruction, accidental
              loss, alteration, unauthorized disclosure or access.
            </p>
            <p>
              Any communications or activities, online or in-person, you may
              have with third parties through Our Website(s) or Mobile
              Application is your responsibility and you indemnify Us, and hold
              Us harmless, from any liability resulting from such communications
              or activities. This includes, without limitation and by way of
              example only, personal injury, property damage, attorneys’ fees,
              costs, and any amounts paid in settlement.
            </p>
          </div>
        </div>
        <div id="section-11" className="row mt-4">
          <div className="col-md-12">
            <h2>California Users</h2>
            <p>
              Under California&apos;s &quot;Shine the Light&quot; Law, and once
              per year, California, U.S. residents who provide Personal Data in
              accessing Our Website(s) or Mobile Application for personal,
              family, or household use are entitled to request and obtain from
              the seller the customer information shared, if any, with other
              businesses for their own direct marketing uses.
            </p>
            <p>
              If applicable, this information would include the categories of
              customer information and the names and addresses of those
              businesses with which We shared customer information during the
              immediately prior calendar year. If you believe this Law applies
              to you, please send an e-mail message to Data Compliance Officer
              at info@manifestmatter.com with &quot;Request for California
              Privacy Information&quot; in the subject line and in the body of
              your message. We will provide the requested information to you at
              your e-mail address in response only if the Law applies to you.
            </p>
            <p>
              We will maintain your request and the customer information you
              provided in that request for ninety (90) days to ensure you do not
              have any follow-up questions. After that time, the information
              associated with your request will be destroyed. Please be aware
              that not all information sharing is covered by the &quot;Shine the
              Light&quot; requirements and only information on covered sharing
              will be included in Our response.
            </p>
            <p>
              In addition, if you are a California resident, the California
              Consumer Privacy Act (“CCPA”) requires Us to disclose the
              following information with respect to Our collection, use, and
              disclosure of Personal Data:
            </p>
            <ul>
              <li>
                During a twelve (12) month time-period, We may collect the
                following categories of Personal Data in order to provide you
                access to Our Website(s) or Mobile Application: name, email or
                postal address, geolocation data and IP addresses, credit card
                and billing address, information about whom you connect to or
                communicate within Our Website, educational information, and
                consumer preference characteristics.
              </li>
              <li>
                We collect Personal Data for the business purposes of providing
                services, promotional offers regarding Our Website(s) or Mobile
                Application or the products and services of Business Partners,
                and processing End User logins.
              </li>
              <li>
                We collect Personal Data from (1) your communications with Us,
                (2) social media that links to Our username(s), (3) your
                downloads of our marketing collateral, (4) your purchase of
                products and services on Our Website(s) or Mobile Application,
                and (5) visitors to Our Website(s) or Mobile Application.
              </li>
              <li>
                In the preceding twelve (12) months, We have disclosed the
                categories of Personal Data only as specified in this Policy.
              </li>
              <li>
                We may share your Personal Data with third parties as specified
                in this Policy.
              </li>
              <li>We do not sell your Personal Data.</li>
            </ul>
            <p>If you reside in California, you have the right to:</p>
            <ul>
              <li>
                request access to your Personal Data, including the categories
                of such information, We have collected about you in the last
                twelve (12) months,
              </li>
              <li>
                request additional details about Our information practices,
                including the sources of collection, the categories of Personal
                Data that We share for a business or commercial purpose, and the
                categories of third parties with whom We share your Personal
                Data,
              </li>
              <li>
                request deletion of your Personal Data (subject to certain
                exceptions),
              </li>
              <li>opt out of sales of Personal Data, if applicable, and</li>
              <li>
                request access to the specific Personal Data shared with other
                businesses for their direct marketing, if any.
              </li>
            </ul>
            <p>
              You may make these requests by calling 608-770-6478, mailing or
              emailing Us at info@manifestmatter.com with &quot;Request for
              California Privacy Information&quot; in the subject line and in
              the body of your message. After submitting your request, please
              monitor your email for a verification email. We will provide the
              requested information to you at your e-mail address in response
              only if the Law applies to you.
            </p>
            <p>
              If this Law is applicable to you, We are required by Law to verify
              your identity prior to deleting your Personal Data in order to
              protect your privacy and security. If you make a request to delete
              your Personal Data, Our Websites, or some of them, may no longer
              be available to you.
            </p>
            <p>
              Please note that you may designate an authorized agent to exercise
              these rights on your behalf by providing written materials
              demonstrating that you have granted the authorized agent power of
              attorney. If an authorized agent submits a request on your behalf,
              We may need to contact you to verify your identity and protect the
              security of your Personal Data. We will not discriminate against
              you if you choose to exercise your rights under the CCPA.
            </p>
            <p>
              We will maintain your request and the Personal Data you provide in
              that request for ninety (90) days to ensure you do not have any
              follow-up questions. After that time, the information associated
              with your request will be deleted.
            </p>
            <p>
              Please be aware that not all businesses are required to comply
              with CCPA requirements, and not all information sharing is covered
              by such CCPA. Only information covered will be included in Our
              response.
            </p>
            <p>
              To learn more about your rights under the CCPA, visit{" "}
              <Link
                to="https://oag.ca.gov/privacy/ccpa"
                aria-label="Read more about your rights under the CCPA"
              >
                https://oag.ca.gov/privacy/ccpa
              </Link>
              .
            </p>
          </div>
        </div>
        <div id="section-12" className="row mt-4">
          <div className="col-md-12">
            <h2>Changes to Privacy Policy</h2>
            <p>
              Any changes We may make to Our Privacy Policy in the future will
              be posted on this page, located at{" "}
              <Link
                to="/privacy-policy"
                aria-label="Read more about the ideal pancakes privacy policy"
              >
                www.idealpancakes.com/privacy-policy
              </Link>{" "}
              and, where appropriate, sent to you by e-mail or provided when you
              next login to Our Website(s) or Mobile Application. The new Terms
              may be displayed on-screen and you may be required to read and
              accept them to continue your Use of Our Website(s) or Mobile
              Application.
            </p>
          </div>
        </div>
        <div id="section-13" className="row mt-4">
          <div className="col-md-12">
            <h2>Complaints</h2>
            <p>
              If you have a complaint regarding this Privacy Policy or how your
              Personal Data is being maintained, used, or processed, you should
              first contact Our Data Compliance Officer, whose contact details
              appear below:
            </p>
            <div>
              <div>Attention: Data Compliance Officer</div>
              <div>info@manifestmatter.com</div>
            </div>
            <br></br>
            <p>
              If you are located in the European Union/European Economic area
              and are still concerned that We are not complying with Our
              obligations under the GDPR or another applicable regulation or
              statute, and/or that We are not handling Personal Data responsibly
              and in line with good practice, you may raise a concern with the
              UK Information Commissioner’s Office (“ICO”). Concerns may be
              reported online at{" "}
              <Link
                to="https://ico.org.uk/concerns/handling/"
                aria-label="Raise a convern with the UK Information Commissioner's Office"
              >
                https://ico.org.uk/concerns/handling/
              </Link>{" "}
              or by calling 0303 123 1113.
            </p>
          </div>
        </div>
        <div id="section-14" className="row mt-4">
          <div className="col-md-12">
            <h2>Contact</h2>
            <p className="col-md-6">
              Questions, comments, notices, and requests regarding this Privacy
              Policy are welcomed and should be emailed to Our Data Compliance
              Officer at info@manifestmatter.com or via our{" "}
              <Link
                to="contact-us"
                aria-label="Link to the ideal pancakes contact form"
              >
                Contact Us
              </Link>{" "}
              page.
            </p>
            <br></br>
            <p>
              Please make sure that you identify the Website(s) or Mobile
              Application through which you submitted Personal Data to enable Us
              to identify your records. We will respond to your communications
              within thirty (30) days unless your request involves information
              that requires significant and/or unusual research.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
