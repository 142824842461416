// Organizing Mock Data Structures (used in local development)
import type { TRecipePreview } from "./DataTypes";
import type { TRecipe } from "./DataTypes";
import type { TTag } from "./DataTypes";
import type { TUser } from "./DataTypes";
import type { TLoginData } from "./DataTypes";

// sample images
// import ImgMeal0 from "../assets/samplesONLY/meal-0.jpg";
// import ImgMeal1 from "../assets/samplesONLY/meal-1.jpg";
// import ImgMeal2 from "../assets/samplesONLY/meal-2.jpg";
// import ImgMeal3 from "../assets/samplesONLY/meal-3.jpg";
import ImgMeal4 from "../assets/samplesONLY/meal-4.jpg";
import ImgMeal5 from "../assets/samplesONLY/meal-5.jpg";
import ImgMeal6 from "../assets/samplesONLY/meal-6.jpg";
import ImgMeal7 from "../assets/samplesONLY/meal-7.jpg";
import ImgMeal8 from "../assets/samplesONLY/meal-8.png";

// shanza photos
// import ImgMeal0 from "../assets/images/SS1.png";
// import ImgMeal1 from "../assets/images/SS2.png";
// import ImgMeal2 from "../assets/images/SS3.png";
// import ImgMeal3 from "../assets/images/SS4.png";

// import wills paella sample recipe photo
// import willPaella from "../assets/images/paella2.png";

const mTitles: string[] = [
  "A hearty soup",
  "Beans and Rice",
  "Luke Warm Cauliflower",
  "Mushroom Peach Cobbler",
  "Donut Cream Puff",
  "Eggs and Toast",
  "Frank and Beans",
  "Egg Salad",
  "Fruit and Nuts",
  "Faux Salamander Pudding",
  "Blanched Cornbread",
  "Soft Rice",
  "Khow Suey",
];
const mAuthors: string[] = ["zeus", "ares"];
const mUserIds: string[] = ["__user0", "__user1"];
const mUsernames: string[] = ["zeus@yahoo.com", "ares@hotmail.gov"];
const mImages: any[] = [
  ImgMeal4,
  ImgMeal4,
  ImgMeal4,
  ImgMeal4,
  ImgMeal4,
  ImgMeal5,
  ImgMeal6,
  ImgMeal7,
  ImgMeal8,
];

export const generatePreviews = (count: number, page: number) => {
  const output: TRecipePreview[] = [...Array(count)].map((_, index) => {
    const i = index + page * 8;
    const mockIndex = i % 13;
    const userIndex = i % 2;
    const imgIndex = i % 9;
    return {
      _recipeId: String(i),
      title: mTitles[mockIndex],
      author: mAuthors[userIndex],
      img: mImages[imgIndex],
    } as TRecipePreview;
  });
  // TEST ADD real recipe here for testings
  if (page === 0) {
    const willsRecipe = {
      _recipeId: String(69),
      title: "Paella",
      author: "William Vennard",
      img: ImgMeal4,
    } as TRecipePreview;
    output.unshift(willsRecipe);
    output.pop();
  }

  // TEST END
  return output;
};
export const generateRecipe = (i: number) => {
  // Wills recipe TEST
  if (i === 69) {
    return {
      _recipeId: String(i),
      _userId: mUserIds[1],
      author: "William Vennard",
      title: "Paella",
      description:
        "A classic Spanish dish done a little differently, but sure to impress your guests.",
      img: ImgMeal4,
      cookTime: "2:00",
      servings: 6,
      tags: ["easy", "good for parties", "spanish"],
      notes:
        "The key to a good Sofrito, is a generous amount of olive oil and plenty of time simmering on the stove, at least 30 minutes to emulsify the ingredients.",
      ingredients: [
        "1 lemon (garnish/toping)",
        "1 large onion diced",
        "1 head of garlic roughly minced",
        "2 bell Peppers diced(red & green for aesthetics)",
        "4-5 roma tomatoes diced",
        "3 Tbs Olive Oil",
        "3 cups Bomba or Arborio Rice (anything short grain works)",
        "6 cups chicken stock",
        "2 pinches of saffron",
        "1 cup frozen peas",
        "0.5 lbs mexican pork chorizo",
        "1.5 lbs chicken breast or thigh cut into smallish peices",
        "0.5 lbs deveined uncooked shrimp (optional)",
        "2 Tbs thyme",
        "2 Tbs paprika",
        "Salt and black Pepper to taste",
      ],
      steps: [
        "First add chorizo to a large pan on medium heat, you want to cook it down and render most of the fat out, about 10-15 minutes. Then move chorizo to paella pan or baking dish.",
        "Next liberally season chicken with thyme paprika black pepper and a little salt. That goes into the pan on high with a dash of oil if necessary for a quick browning, just give em a few minutes a side. Not going for fully cooked, just get some good color and then transfer to baking dish with chorizo.",
        "Preheat oven to 375 F",
        "Next up is the sofrito, to the same large pan on medium heat add olive oil, onion, garlic, bell peppers, and tomatoes. You basically can’t cook this long enough, but typically about 20min is sufficient. Everything should start blending together. Also feel free to adjust the amount of olive oil as the chorizo will have left some fat in the pan. I’ll usually still add more olive oil along the way.",
        "Make a well in the middle of the sofrito and add in the rice, cook a few minutes until the rice starts turning translucent around the edges.",
        "Pour in chicken stock and add saffron, stir and bring to almost a boil then transfer into baking dish.",
        "Top with frozen peas and pop in the oven for about 30-45 minutes.",
        "The liquid should almost be completely absorbed when you take it out. Cover with linen cloth and let stand for at least 10 minutes before serving.",
      ],
    } as TRecipe;
  }

  // END Wills recipe TEST
  const mockIndex = i % 11;
  const userIndex = i % 2;
  const imgIndex = i % 9;
  return {
    _recipeId: String(i),
    _userId: mUserIds[userIndex],
    author: mAuthors[userIndex],
    title: mTitles[mockIndex],
    description:
      "A simple curry paste gives this northern Thai-inspired soup surprising depth of flavor.",
    img: mImages[imgIndex],
    cookTime: "1:00",
    servings: 2,
    tags: ["healthy", "vegan", "gluten free"],
    notes:
      "Try not to mess it up, as long as you dont mess it up, itll turn out great! This is a mock note, blah blah blah blah blah blah blah blah blah blah.",
    ingredients: ["1 lb rice", "5 g flour", "20 gal water"],
    steps: [
      "Slowly add water to your dry ingredients while also hopping on one foot.",
      "Then take your free hand and, while continuing to stir, start waving it vigorously over the mixtures and chant fa rah rah rah fa rah rah rah this is a long a step, fah rah rah fah rah rah.",
      "You are done.><",
    ],
  } as TRecipe;
};

export const tagsList: string[] = [
  "Appetizers",
  "Asian",
  "BBQ/Grilling",
  "Baking",
  "Beverages",
  "Breakfast",
  "Brunch",
  "Comfort Food",
  "Dairy-Free",
  "Desserts",
  "Dinner",
  "Fast",
  "Farm-to-Table",
  "Fusion",
  "Gluten-Free",
  "Gourmet",
  "Healthy",
  "High-Protein",
  "Italian",
  "Kid-Friendly",
  "Keto",
  "Low-Calorie",
  "Low-Carb",
  "Low-Fat",
  "Lunch",
  "Mediterranean",
  "Mexican",
  "Paleo",
  "Picnic",
  "Potluck",
  "Regional Cuisine",
  "Salads",
  "Seafood",
  "Snacks",
  "Soups",
  "Spanish",
  "Street Food",
  "Vegan",
  "Vegetarian",
  "Whole30",
];

export const mUser: TUser = {
  _userId: mUserIds[0],
  username: mUsernames[0],
  _token: "",
  author: mAuthors[0],
  dateLastLogin: "",
  dateUserCreated: "",
  statRecipesMaintained: 2,
};

export async function fakeDelay() {
  return new Promise((res) => {
    setTimeout(res, Math.random() * 800);
  });
}
