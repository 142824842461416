import React from "react";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";
import Popper from "popper.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./routes/root";
import ErrorPage from "./components/errorPage/ErrorPage";

import Search from "./routes/search";

import Recipe from "./routes/recipe";
import RecipeEdit from "./routes/recipeEdit";
import { action as destroyAction } from "./routes/recipeDestroy";

import Profile from "./routes/profile";
import SignUp from "./routes/signUp";

import RecoverPassword from "./routes/recoverPassword";

import AboutUs from "./routes/aboutUs";
import ContactUs from "./routes/contactUs";
import TermsAndConditions from "./routes/termsAndConditions";
import PrivacyPolicy from "./routes/privacyPolicy";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Search />,
          errorElement: <ErrorPage />,
        },
        {
          path: "recipe/:recipeId",
          element: <Recipe />,
          errorElement: <ErrorPage />,
        },
        {
          path: "recipe/:recipeId/edit",
          element: <RecipeEdit />,
          errorElement: <ErrorPage />,
        },
        {
          path: "recipe/:recipeId/destroy",
          errorElement: <ErrorPage />,
          action: destroyAction,
        },
        {
          path: "profile",
          element: <Profile />,
          errorElement: <ErrorPage />,
        },
        {
          path: "sign-up",
          element: <SignUp />,
          errorElement: <ErrorPage />,
        },
        {
          path: "recover-password",
          element: <RecoverPassword />,
          errorElement: <ErrorPage />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
          errorElement: <ErrorPage />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
          errorElement: <ErrorPage />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsAndConditions />,
          errorElement: <ErrorPage />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
