import React, { useState, useEffect } from "react";
import "./Profile.css";
import { Form, useNavigate, useLocation, Link } from "react-router-dom";
import {
  signIn,
  fetchAuthSession,
  signOut,
  updatePassword,
} from "aws-amplify/auth";
import { getToken } from "../../utils/authToken";
import { passwordValidator } from "../../utils/validation";
import type { TUser, TLoginData } from "../DataTypes";
import LoginFormView from "./LoginFormView";
import landingImage from "../../assets/images/logo-tranparent-idealpancakes.png";
import whiteLandingImage from "../../assets/images/white-IP-Logo.png";
import { getApiEndpoint } from "../../api/endpoints";
import SyrupLoader from "../../components/common/syrupLoader/SyrupLoader";
import { MAX_AUTHOR_INPUT } from "../../utils/constants";
import { getRecaptchaScore } from "../../utils/reCaptcha";

interface Props {
  user: TUser;
  auth: boolean;
  darkmode: boolean;
  setDarkmode: any;
  setAuth: any;
  setUser: any;
}

const ProfileView = (props: Props) => {
  const [loading, setLoading] = useState(false as boolean);
  const navigate = useNavigate();
  const location = useLocation();
  const [author, setAuthor] = useState("" as string);
  useEffect(() => {
    if (props.user.author) setAuthor(props.user.author);
  }, [props.user]);
  // NOTE: initializing fields required to avoid "uncontrolled input" warnings from react
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    newLoginPassword: "",
    oldPassword: "",
    newPassword: "",
  } as TLoginData);

  const [errorMsg, setErrorMsg] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const redirectPath = queryParameters.get("redirect");
  const [landingImgSrc, setLandingImgSrc]: any = useState(landingImage);
  useEffect(() => {
    if (props.darkmode) {
      setLandingImgSrc(whiteLandingImage);
    } else {
      setLandingImgSrc(landingImage);
    }
  }, [props.darkmode]);

  // handles all login and update password form field updates
  const handleChange = (event: any) => {
    const name = event.target.name;
    setLoginData({ ...loginData, [name]: event.target.value });
  };

  // handles all login form and update password form submits
  const handleLoginSubmit = (formType: string) => {
    if (formType === "login") {
      handleLogin();
    } else if (formType === "updatePassword") {
      handleUpdatePassword();
    }
  };
  // ToDo: handles profile update actions
  const handleProfileUpdate = async () => {
    // dont call if author name unchanged
    if (author === props.user.author) return;
    setLoading(true);
    setErrorMsg("");
    // check if author name taken
    try {
      const headers = {
        "Content-Type": "application/json", // Adjust content type if needed
      };

      const response = await fetch(getApiEndpoint("profile") + "/" + author, {
        headers,
      });

      // const result = await response.json();
      const status = await response.status;
      if (status === 200) {
        console.warn("Author name is NOT taken!");
        // save new author name with PUT
        const postData = { display_name: author };
        try {
          const headers = {
            Authorization: `Bearer ${props.user._token}`,
            "Content-Type": "application/json",
          };
          const response = await fetch(getApiEndpoint("profile"), {
            method: "PUT",
            headers,
            body: JSON.stringify(postData),
          });

          const status = response.status;
          setLoading(false);
          if (status === 200) {
            // set user data on front-end
            props.setUser({
              ...props.user,
              author: author,
            });
          }
        } catch (error) {
          setLoading(false);
          console.error("Error making POST request:", error);
        }
      } else if (status === 400) {
        console.warn("Author name IS taken!");
        setErrorMsg("Sorry, that display name is already taken.");
        setLoading(false);
      } else {
        setErrorMsg("Oops, something went wrong!");
        setLoading(false);
      }
      // check for redirect here for debug purposes
    } catch (error) {
      setLoading(false);
      setErrorMsg(String(error));
      console.error("get profile data, Error fetching data:", error);
    }
  };

  // -- cognito auth login functions --
  const handleLogin = async () => {
    // do validation here
    if (loginData.username && loginData.password) {
      setLoading(true);
      // then recapthca
      const isHuman = await getRecaptchaScore("login");
      if (isHuman) {
        setErrorMsg("");
        // logout first
        try {
          await signOut();
        } catch (error) {
          console.log("handleLogout: error =", error);
        }
        const username = loginData.username;
        const password = loginData.password;

        try {
          const { isSignedIn, nextStep } = await signIn({
            username,
            password,
          });

          // now get the auth token
          const authToken = await getToken();
          // before switching the view, get credentials
          try {
            const headers = {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json", // Adjust content type if needed
            };

            const response = await fetch(getApiEndpoint("profile"), {
              headers,
            });

            const result = await response.json();
            props.setUser({
              ...props.user,
              username: result.email,
              _token: authToken,
              author: result.display_name,
            });

            // if valid then continue with login
            props.setAuth(true);
            setLoading(false);

            // think we can avoid doing it like this, one second
            const previousPathname = location.state?.from;
            if (previousPathname) {
              // now we can exclude these pages
              // sign-up
              // recover-password
              if (
                previousPathname !== "/sign-up" &&
                previousPathname !== "/recover-password"
              ) {
                navigate(previousPathname);
              }
            }
          } catch (error) {
            setLoading(false);
            setErrorMsg(String(error));
            console.error("get profile data, Error fetching data:", error);
          }
        } catch (error: any) {
          setLoading(false);
          setErrorMsg(error.message);
          console.log("error signing in error = ", error);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleUpdatePassword = async function () {
    // check valid new password
    if (passwordValidator(loginData.newPassword)) {
      setLoading(true);
      const oldPassword = loginData.oldPassword;
      const newPassword = loginData.newPassword;
      console.log("handleUpdatePassword: oldPassword = ", oldPassword);
      console.log("handleUpdatePassword: newPassword = ", newPassword);
      try {
        await updatePassword({ oldPassword, newPassword });
        setLoading(false);
        // ToDo want to show a success message, and clear form
        setLoginData({ ...loginData, oldPassword: "", newPassword: "" });
        console.log("password updated successfully!");
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  // -- laying out possible views --
  // login page
  let output = (
    <div className="col-md-4 offset-md-4">
      <SyrupLoader loading={loading} />
      <div className="login-container p-4" hidden={loading}>
        <div
          className="login-message p-4"
          hidden={!redirectPath?.includes("recipe/-1/edit")}
        >
          <p className="text-center">
            Please login or create an account before crafting a recipe.
          </p>
        </div>

        <LoginFormView
          formType="login"
          title=""
          btnTitle="Login"
          data={loginData}
          onChange={handleChange}
          handleSubmit={handleLoginSubmit}
        />

        <div className="text-center mt-2">
          <div hidden={!errorMsg} className="col-md-12 text-danger">
            {errorMsg}
          </div>
          <div>
            <Link className="btn nav-btn" to={`/recover-password`}>
              - forgot password -
            </Link>
          </div>
          <div>
            <Link className="btn nav-btn" to={`/sign-up`}>
              - create account -
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  if (props.auth && props.user.username) {
    // logged in view of profile page
    output = (
      <div>
        <div className="row manage-profile">
          <div className="col-md-4 offset-md-2">
            <h1>Manage Profile</h1>
            <div className="accordion accordion-flush" id="profileOptions">
              <div className="accordion-item">
                <h2 className="accordion-header" id="profileInfoOption">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush1"
                    aria-expanded="false"
                    aria-controls="flush1"
                  >
                    Profile Info
                  </button>
                </h2>
                <div
                  id="flush1"
                  className="accordion-collapse collapse show"
                  aria-labelledby="profileInfoOption"
                  data-bs-parent="#profileOptions"
                >
                  <div className="accordion-body">
                    <h3>Static Attributes</h3>
                    <div>Username - {props.user.username}</div>
                    <h3>Adjustable Attributes</h3>
                    <div>Display Name - {author}</div>
                    {/* <h3>Profile Statistics</h3>
                    <div>Last Logged On - {props.user.dateLastLogin}</div>
                    <div>User Since - {props.user.dateUserCreated}</div>
                    <div>
                      Recipes Maintained - {props.user.statRecipesMaintained}
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="profileUpdateOption">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush2"
                    aria-expanded="false"
                    aria-controls="flush2"
                  >
                    Update Profile
                  </button>
                </h2>
                <div
                  id="flush2"
                  className="accordion-collapse collapse"
                  aria-labelledby="profileUpdateOption"
                  data-bs-parent="#profileOptions"
                >
                  <div className="accordion-body">
                    <SyrupLoader loading={loading} class={"mini"} />
                    <Form
                      method="post"
                      id="profile-form"
                      className="row"
                      hidden={loading}
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="author"
                          className="form-label font-weight-light text-muted"
                        >
                          Display Name
                        </label>
                        <input
                          value={author}
                          onChange={(event: any) =>
                            setAuthor(event.target.value)
                          }
                          className="form-control"
                          id="author"
                          aria-label="author"
                          type="text"
                          name="author"
                          maxLength={MAX_AUTHOR_INPUT}
                        />
                        <div
                          hidden={author.length !== MAX_AUTHOR_INPUT}
                          className="form-text"
                        >
                          character limit - {MAX_AUTHOR_INPUT}
                        </div>
                        <div id="authorHelp" className="form-text">
                          Your display name is what appears publicly on all
                          recipes you create.
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switchDarkmode"
                            onClick={() => {
                              props.setDarkmode((mode: boolean) => {
                                return !mode;
                              });
                              // store setting in localStorage
                              const outputStr = !props.darkmode
                                ? "true"
                                : "false";
                              localStorage.setItem(
                                "idealpancakes.darkmode",
                                outputStr
                              );
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="switchDarkmode"
                          >
                            Enable Dark Mode
                          </label>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-primary login-btn"
                          type="button"
                          onClick={() => handleProfileUpdate()}
                        >
                          Update
                        </button>
                      </div>
                      <div hidden={!errorMsg} className="text-danger">
                        {errorMsg}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="updatePasswordOption">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush3"
                    aria-expanded="false"
                    aria-controls="flush3"
                  >
                    Update Password
                  </button>
                </h2>
                <div
                  id="flush3"
                  className="accordion-collapse collapse"
                  aria-labelledby="updatePasswordOption"
                  data-bs-parent="#profileOptions"
                >
                  <div className="accordion-body">
                    <SyrupLoader loading={loading} class={"mini"} />
                    <div hidden={loading}>
                      <LoginFormView
                        formType="updatePassword"
                        title=""
                        btnTitle="Update"
                        data={loginData}
                        onChange={handleChange}
                        handleSubmit={handleLoginSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 profile-view-img">
            <img className="mx-auto" src={landingImgSrc} alt="" />
          </div>
        </div>
        <div className="graphic-wave"></div>
      </div>
    );
  }

  return <div className="profile-root mt-5">{output}</div>;
};
export default ProfileView;
