import React, { useEffect, useState } from "react";
import "../App.css";
import {
  useOutletContext,
  json,
  useLoaderData,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import RecipeView from "../components/recipe/RecipeView";
import { getToken } from "../utils/authToken";
import { blankRecipe } from "../components/DataTypes";
import SyrupLoader from "../components/common/syrupLoader/SyrupLoader";

export default function Recipe() {
  const [
    auth,
    setAuth,
    user,
    setUser,
    darkmode,
    setDarkmode,
    nav,
    setNav,
    setError,
  ]: any = useOutletContext();
  const navigate = useNavigate();
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(blankRecipe);
  const [loading, setLoading] = useState(true as boolean);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    setNav({
      ...nav,
      owned: user.author === recipe.author,
      page: "recipe",
      recipeTitle: recipe.title,
      _recipeId: recipe._recipeId,
    });
    // get recipe
    // ToDo: Check for param NEEDS APROVAL param is review=true
    // if needs approval use different endpoint that needs auth
    // alternat url path is https://api.idealpancakes.com/admin/recipe/ + id
    // gets recipe that needs review - proteted by auth
    const getReviewRecipe = async function () {
      const authToken = await getToken();
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json", // Adjust content type if needed
        };

        const response = await fetch(
          "https://api.idealpancakes.com/admin/recipe/" + recipeId,
          {
            headers,
          }
        );

        const result = await response.json();
        setRecipe(result);
        setLoading(false);
      } catch (error: any) {
        // ToDo: if error or no profile found, boot them back to login page
        console.error("get recipe data, Error fetching data:", error);
        setLoading(false);
        setError(error.message);
      }
    };
    const getRecipe = async function () {
      try {
        const headers = {
          "Content-Type": "application/json", // Adjust content type if needed
        };

        const response = await fetch(
          "https://api.idealpancakes.com/recipe/" + recipeId,
          {
            headers,
          }
        );

        const result = await response.json();
        setRecipe(result);
        setLoading(false);
      } catch (error: any) {
        // ToDo: if error or no profile found, boot them back to login page
        console.error("get recipe data, Error fetching data:", error);
        setLoading(false);
        setError(error.message);
      }
    };
    // check for params here
    const review = searchParams.get("review");
    console.warn("reveiew  param = == ", review);
    if (review === "true") {
      // getRecipe with auth
      getReviewRecipe();
    } else {
      getRecipe();
    }
  }, []);

  return (
    <div>
      <SyrupLoader loading={loading} />
      <div hidden={loading}>
        <RecipeView
          owned={user.author === recipe.author}
          recipe={recipe}
          handleListRemove={() => Function.prototype()} // noop stub while in view mode
          userID={user._userId}
        />
      </div>
    </div>
  );
}
