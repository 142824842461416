import React, { useEffect } from "react";
import "../App.css";
import { useOutletContext } from "react-router-dom";

import ProfileView from "../components/profile/ProfileView";

export default function Profile() {
  const [
    auth,
    setAuth,
    user,
    setUser,
    darkmode,
    setDarkmode,
    nav,
    setNav,
    setError,
  ]: any = useOutletContext();

  useEffect(() => {
    setNav({ ...nav, page: "profile" });
  }, []);

  return (
    <div className="row">
      <ProfileView
        user={user}
        auth={auth}
        darkmode={darkmode}
        setDarkmode={setDarkmode}
        setAuth={setAuth}
        setUser={setUser}
      />
    </div>
  );
}
