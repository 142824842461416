import React, { useState, useEffect } from "react";
import "../App.css";
import { Outlet } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { getToken } from "../utils/authToken";
import { getApiEndpoint } from "../api/endpoints";

import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import type { TUser, TNavData } from "../components/DataTypes";

import { profile } from "console";

// -- client side cognito settings --
const isLocal: boolean = process.env.NODE_ENV !== "production";
const redirectURL: string = isLocal
  ? "https://localhost:3000/"
  : "https://www.idealpancakes.com/";
Amplify.configure({
  Auth: {
    Cognito: {
      // region: "us-east-1",
      userPoolId: "us-east-1_ZtmXs9t3L",
      userPoolClientId: "15jh2l0t47us4ulsrbk4h3go0i",
      loginWith: {
        oauth: {
          domain: "https://authenticator.idealpancakes.com",
          scopes: ["email", "openid"],
          redirectSignIn: [redirectURL],
          redirectSignOut: [redirectURL],
          responseType: "code",
        },
      },
    },
  },
});

export default function Root() {
  const [auth, setAuth] = useState("not checked" as any);
  const [darkmode, setDarkmode] = useState(false);

  // const blankUser = {
  //   _userId: "",
  //   username: "",
  //   _token: "",
  //   author: "",
  //   dateLastLogin: "",
  //   dateUserCreated: "",
  //   statRecipesMaintained: 0,
  // };

  const [user, setUser] = useState({} as TUser);
  const [nav, setNav] = useState({} as TNavData);
  const [error, setError] = useState("");

  const getUserOnLoad = async function () {
    const authToken = await getToken();
    // if no auth token then assume not signed in
    if (authToken === "undefined") {
      setAuth(false);
    } else {
      // then call get profile and set info
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(getApiEndpoint("profile"), {
          headers,
        });
        const result = await response.json();
        console.warn("getUserOnLoad, user data result = ", result);

        if (!response.ok) {
          // If response status is not ok, throw an error
          setAuth(false);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setUser({
          ...user,
          _token: authToken,
          username: result.email,
          author: result.display_name,
        } as any);
        setAuth(true);
      } catch (error) {
        console.error("getUserOnLoad, Error fetching data:", error);
        setAuth(false);
      }
    }
  };

  useEffect(() => {
    getUserOnLoad();

    // load darkmode setting from local browser storage if available
    const localDarkmode = localStorage.getItem("idealpancakes.darkmode");
    if (localDarkmode !== null) {
      const newMode = localDarkmode === "true" ? true : false;
      setDarkmode(newMode);
    }
  }, []);

  let errorBanner = null;
  let spacing = ""; // spacing needed if error banner is not displayed // wv- want to get rid of the space
  if (error.length > 0) {
    errorBanner = (
      <div className="alert alert-primary mt-5" role="alert">
        {error}
      </div>
    );
    spacing = "";
  }

  const mode = darkmode ? "dark" : "light";
  return (
    <div className="root" data-theme={mode}>
      <div className="nav">
        <Navbar
          user={user}
          auth={auth}
          setAuth={setAuth}
          setUser={setUser}
          navData={nav}
        />
      </div>
      <div className="content">
        {errorBanner}
        <div className={spacing}>
          <Outlet
            context={[
              auth,
              setAuth,
              user,
              setUser,
              darkmode,
              setDarkmode,
              nav,
              setNav,
              setError,
            ]}
          />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}
