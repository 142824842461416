import React from "react";
import { Link } from "react-router-dom";
import "./termsAndConditions.css";

// interface Props {}
const TermsAndConditionsPage = (props: any) => {
  return (
    <div className="col-md-10 offset-md-1 terms-conditions-container">
      <div className="row m-4">
        <h1>Terms and Conditions for Ideal Pancakes</h1>
        <p>Effective Date: 09/27/2023</p>
        <p>
          Please read these Terms and Conditions (&quot;Terms,&quot; &quot;Terms
          and Conditions&quot;) carefully before using Ideal Pancakes (the
          &quot;Website&quot;) operated by Manifest Matter LLC (&quot;us,&quot;
          &quot;we,&quot; or &quot;our&quot;).
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our Website, you agree to comply with and be
          bound by these Terms and Conditions. If you do not agree to these
          Terms, please do not use the Website.
        </p>
        <h2>2. Use of the Website</h2>
        <p>
          2.1. Eligibility: You must be at least 18 years old to use our
          Website. By using the Website, you represent and warrant that you are
          at least 18 years old.
        </p>
        <p>
          2.2. User Accounts: If the Website requires you to create an account,
          you are responsible for maintaining the confidentiality of your
          account information and password. You agree to accept responsibility
          for all activities that occur under your account. You must notify us
          immediately if you suspect any unauthorized access to or use of your
          account.
        </p>
        <p>
          2.3. Prohibited Activities: You agree not to engage in any of the
          following activities on the Website:
        </p>
        <div className="mx-5">
          <p>a. Violating any applicable laws or regulations.</p>
          <p>
            b. Uploading, posting, or sharing content that is illegal,
            offensive, or violates the rights of others.
          </p>
          <p>
            c. Engaging in any form of harassment, hate speech, or bullying.
          </p>
        </div>
        <h2>3. Content and Recipes</h2>
        <p>
          3.1. User-Generated Content: By posting or sharing content on our
          Website, including recipes, comments, or other materials, you grant us
          a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable
          license to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, and display such content.
        </p>
        <p>
          3.2. Accuracy: We do not guarantee the accuracy, completeness, or
          reliability of any content on the Website, including recipes. Users
          are responsible for verifying the accuracy and safety of any recipe or
          information provided.
        </p>
        <h2>4. Intellectual Property</h2>

        <p>
          4.1. Ownership: All content on the Website, including text, graphics,
          logos, images, audio, and video, is the property of Mannifest Matter
          LLC and is protected by intellectual property laws.
        </p>
        <p>
          4.2. Use of Content: You may not reproduce, distribute, modify, create
          derivative works from, or publicly display any content from the
          Website without our prior written consent.
        </p>
        <h2>5. Privacy</h2>
        <p>
          Please review our{" "}
          <Link
            to="/privacy-policy"
            aria-label="Read more about our privacy policy"
          >
            Privacy Policy
          </Link>{" "}
          to understand how we collect, use, and protect your personal
          information.
        </p>
        <h2>6. Termination</h2>
        <p>
          We reserve the right to terminate or suspend your access to the
          Website without prior notice for any reason, including a breach of
          these Terms.
        </p>
        <h2>7. Disclaimer of Warranties</h2>
        <p>
          The Website is provided on an &quot;as-is&quot; and &quot;as
          available&quot; basis. We make no warranties, expressed or implied,
          regarding the reliability, accuracy, or availability of the Website.
        </p>
        <h2>8. Limitation of Liability</h2>
        <p>
          We shall not be liable for any direct, indirect, incidental, special,
          or consequential damages arising out of or in any way connected with
          the use of the Website.
        </p>
        <h2>9. Changes to Terms and Conditions</h2>
        <p>
          We reserve the right to modify or replace these Terms and Conditions
          at any time. Your continued use of the Website after changes are
          posted constitutes your acceptance of the modified Terms.
        </p>
        <h2>10. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of The United States.
        </p>
        <h2>11. Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us at through our{" "}
          <Link
            className="footer-spacing"
            to={`/contact-us`}
            aria-label="Link to our Contact page"
          >
            Contact Page
          </Link>
          .
        </p>
        <p>
          By using our Website, you agree to these Terms and Conditions. If you
          do not agree with any part of these terms, please do not use our
          Website.
        </p>
        <p>Thank you for using Ideal Pancakes!</p>
        <p>Manifest Matter LLC</p>
        <p>Denver, CO</p>
        <p>1/1/2024</p>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
