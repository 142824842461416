import React, { useEffect } from "react";
import "../App.css";
import { useOutletContext } from "react-router-dom";
import ContactUsPage from "../components/contactUs/contactUs";

export default function ContactUs() {
  const [
    auth,
    setAuth,
    user,
    setUser,
    darkmode,
    setDarkmode,
    nav,
    setNav,
    setError,
    setLoading,
  ]: any = useOutletContext();

  useEffect(() => {
    // setNav({ ...nav, page: "profile" });
  }, []);

  return (
    <div className="row">
      <ContactUsPage user={user}></ContactUsPage>
    </div>
  );
}
