import React from "react";
import { Form } from "react-router-dom";

import "./Profile.css";
import type { TLoginData } from "../DataTypes";

import { passwordValidator, emailValidator } from "../../utils/validation";
// -- inner input component --
interface InputProps {
  name: string;
  type: string;
  title: string;
  help: boolean;
  value: string;
  onChange: any;
}

const PasswordValidatorIcons = (props: any) => {
  return (
    <div className="validator-icons">
      <i
        hidden={passwordValidator(props.value, props.condition)}
        className="bi bi-x-circle"
      ></i>
      <i
        hidden={!passwordValidator(props.value, props.condition)}
        className={`bi bi-check2-circle${
          passwordValidator(props.value, props.condition) ? " valid" : ""
        }`}
      ></i>
    </div>
  );
};
const PasswordHelp = (props: any) => {
  return (
    <div id="passwordHelp" className="password-help">
      <div>
        <PasswordValidatorIcons condition={1} value={props.value} />
        <span> minimum 8 characters</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={2} value={props.value} />
        <span> at least one upper and lower character</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={3} value={props.value} />
        <span> at least one special character</span>
      </div>
    </div>
  );
};

const InputField = (props: InputProps) => {
  const passwordResetLink = <div />;
  let passwordHelpView = <div />;
  if (props.type === "password" && props.help === true) {
    passwordHelpView = <PasswordHelp value={props.value} />;
  }
  return (
    <div className="mb-3">
      <input
        placeholder={props.title}
        value={props.value}
        onChange={(event: any) => props.onChange(event)}
        type={props.type}
        name={props.name}
        className="form-control"
      />
      {passwordResetLink}
      {passwordHelpView}
    </div>
  );
};

interface Props {
  formType: string; // login, updatePassword
  title: string;
  data: TLoginData;
  onChange: any;
  handleSubmit: any;
  btnTitle: string;
}
const LoginFormView = (props: Props) => {
  // default view is login, leaving formType for readability
  let output = (
    <div className="col-md-12">
      <InputField
        help={false}
        name="username"
        type="email"
        title="Email"
        value={props.data.username}
        onChange={props.onChange}
      />
      <InputField
        help={false}
        name="password"
        type="password"
        title="Password"
        value={props.data.password}
        onChange={props.onChange}
      />
      {/* reCaptcha - required to display in workflow */}
      <div className="text-muted recaptcha-text m-1">
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
      {/* END reCaptcha - required to display in workflow */}
    </div>
  );
  if (props.formType === "updatePassword") {
    output = (
      <div>
        <InputField
          help={false}
          name="oldPassword"
          type="password"
          title="Old Password"
          value={props.data.oldPassword}
          onChange={props.onChange}
        />
        <InputField
          help={true}
          name="newPassword"
          type="password"
          title="New Password"
          value={props.data.newPassword}
          onChange={props.onChange}
        />
      </div>
    );
  }

  return (
    <div>
      <h3>{props.title}</h3>
      <Form id="login-form" className="">
        {output}

        <button
          type="button"
          className="btn btn-primary login-btn w-100"
          onClick={() => props.handleSubmit(props.formType)}
        >
          {props.btnTitle}
        </button>
      </Form>
    </div>
  );
};

export default LoginFormView;
