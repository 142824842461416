import React, { useState, useEffect } from "react";
import "./Recipe.css";
import {
  useNavigation,
  useNavigate,
  ParamParseKey,
  useParams,
} from "react-router-dom";
import { getToken } from "../../utils/authToken";
import type { TRecipe } from "../DataTypes";
import Resizer from "react-image-file-resizer";

interface Props {
  recipe: TRecipe;
  handleListRemove: any;
  owned: boolean;
  userID: string;
}

const pluralize = (val: any, word: any, plural = word + "s") => {
  const _pluralize = (num: any, word: any, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === "object")
    return (num: any, word: any) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

const RecipeView = (props: Props) => {
  const isEditPage = location.pathname.includes("/edit");

  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false as boolean);
  const { recipeId } = useParams();
  const [showDelete, setShowDelete] = useState(false as boolean);

  useEffect(() => {
    const getLikedInfo = async function () {
      // dont call for edit not real id
      if (!recipeId || recipeId === "-1") {
        return;
      }
      const authToken = await getToken();
      // dont call if no token
      if (authToken === "undefined") {
        return;
      }
      // get LIKED information
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json", // Adjust content type if needed
        };
        // ToDo remove hardcode
        const response = await fetch(
          "https://api.idealpancakes.com/recipe/" + recipeId + "/review",
          {
            headers,
          }
        );
        const result = await response.json();
        setLiked(result);
      } catch (error) {
        console.error("get recipe LIKED data, Error fetching data:", error);
      }
    };
    // only need to do this on result view page
    getLikedInfo();
  }, []);

  const likeRecipe = async () => {
    const authToken = await getToken();
    if (authToken !== "undefined") {
      // Like RECIPE POST
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };

        const response = await fetch(
          "https://api.idealpancakes.com/recipe/" + recipeId + "/review",
          {
            method: "POST",
            headers,
            body: JSON.stringify({}),
          }
        );
        if (response.status === 200) setLiked(!liked);
      } catch (error) {
        console.error("Error making POST request:", error);
      }
    } else {
      // display message to promt login or create an account for 3-5 seconds
      setShowLoginMessage(true);
      const timeout = setTimeout(() => {
        // Set the boolean back to false after 3 seconds
        setShowLoginMessage(false);
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => {
        // Clear the timeout if the component unmounts before 3 seconds
        clearTimeout(timeout);
      };
    }
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  };
  // const [resizedImage, setResizedImage] = useState(null as any);

  const handleBase64Input = (base64String: any, isApproved: boolean) => {
    const blob = dataURItoBlob(base64String);
    // Resize the image using react-image-file-resizer
    Resizer.imageFileResizer(
      blob,
      800, // New width
      800, // New height
      "JPEG", // Format (options: JPEG, PNG, WEBP)
      50, // Quality (0 to 100)
      0, // Rotation
      (uri) => {
        // uri is the resized image data URI
        reviewRecipe(isApproved, uri);
      },
      "base64" // Output type (options: base64, blob, file)
    );
  };

  // add second image copy for thumbnail, make sure total mem doesnt exceed 10mb
  // do this when approving a recipe
  const reviewRecipeBtn = (isApproved: boolean) => {
    // set loading
    handleBase64Input(props.recipe.img, isApproved);
  };

  const reviewRecipe = async (isApproved: boolean, reducedImage: any) => {
    const authToken = await getToken();
    if (authToken !== "undefined") {
      // Like RECIPE POST
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };
        // reduce image for thumbnail
        const response = await fetch(
          "https://api.idealpancakes.com/recipe/" + recipeId + "/approve",
          {
            method: "POST",
            headers,
            body: JSON.stringify({
              approve: isApproved,
              thumbnail: reducedImage,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Server rejected the request");
        }
        // console.warn("response =", response);
        navigate("/recipe/" + recipeId);
        location.reload();
      } catch (error) {
        console.error("Error making POST request:", error);
      }
    } else {
      // display message to promt login or create an account for 3-5 seconds
      setShowLoginMessage(true);
      const timeout = setTimeout(() => {
        // Set the boolean back to false after 3 seconds
        setShowLoginMessage(false);
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => {
        // Clear the timeout if the component unmounts before 3 seconds
        clearTimeout(timeout);
      };
    }
  };

  // TO DO: DELETE RECIPE
  // with auth and must own recipe
  // DELETE method on /recipe/{recipe id here}
  const [confirmDelete, setConfirmDelete] = useState("" as string);
  const handleChange = (event: any) => {
    const value = event.target.value;
    setConfirmDelete(value);
  };
  const showDeleteRecipe = () => {
    setShowDelete(true);
  };
  const deleteRecipe = async (event: any) => {
    event.preventDefault();
    try {
      const authToken = await getToken();
      // ToDo: Perform the server request here
      const urlPath = "https://api.idealpancakes.com/recipe/" + recipeId;
      const response = await fetch(urlPath, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Server rejected the request");
      }
      // console.warn("delete response = ", response);
      setConfirmDelete("");
      navigate(-1);
    } catch (error: any) {
      console.warn("deleteRecipe: error = ", error);
      console.warn("deleteRecipe: error message = ", error.message);
      setConfirmDelete("");
    }
  };

  let output = (
    <div className={`row ${isEditPage ? "is-edit-page" : ""}`}>
      <div className="p-0 col-md-6">
        <div className="recipe-info">
          <div>
            <h1>{props.recipe.title}</h1>

            <p>
              - <i>{props.recipe.author}</i> -
            </p>

            <div>
              {props.recipe.cookTime} <i className="bi bi-clock"></i>
            </div>
            <div>
              {String(props.recipe.servings)}{" "}
              {pluralize(props.recipe.servings, "serving")}
            </div>
            {showLoginMessage && <div>Please login to like a recipe!</div>}
          </div>

          <i
            className={`bi btn like-btn ${
              liked ? "bi-heart-fill" : "bi-heart"
            }`}
            hidden={isEditPage}
            aria-label="Like Recipe"
            onClick={likeRecipe}
          ></i>
        </div>
      </div>
      <div className="p-0 col-md-6 d-flex align-items-center">
        <img
          hidden={props.recipe.img === ""}
          alt={"image of " + props.recipe.title}
          src={props.recipe.img}
          className="recipe-img img-fluid"
        />
        <div
          hidden={props.recipe.img !== ""}
          className="recipe-img recipe-img-placeholder"
        >
          <div> Image will go here</div>
        </div>
      </div>

      <div className="col-md-12 description-text">
        <p>
          <i>{props.recipe.description}</i>
        </p>
      </div>
      <hr></hr>
      <div className="row recipe-body">
        <div className="col-xs-6 col-md-4 pt-2">
          <h2 className="py-3">Ingredients</h2>

          {props.recipe.ingredients.map((el, i) => {
            return (
              <div
                key={"ingredient-" + i}
                className="ingredient form-check"
                onClick={() => props.handleListRemove(i, "ingredients")}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={"ingredientCheck" + i}
                />
                <label
                  className="ingredient-label"
                  htmlFor={"ingredientCheck" + i}
                >
                  {el}
                </label>
              </div>
            );
          })}
        </div>
        <div className="col-xs-6 col-md-8 pt-2">
          <h2 className="py-3">Preparation</h2>
          {props.recipe.steps.map((el, i) => {
            return (
              <div
                key={"step-" + i}
                onClick={() => props.handleListRemove(i, "steps")}
              >
                <div className="row step py-2">
                  <div className="col-sm-1 step-number">{i + 1}</div>
                  <span className="col-sm-11">{el}</span>
                </div>
                <hr hidden={i === props.recipe.steps.length - 1} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-md-12 recipe-tags">
        {props.recipe.tags.map((el, i) => {
          return (
            <span
              className="recipe-tag"
              key={"dietary-tag-" + i}
              onClick={() => props.handleListRemove(i, "tags")}
            >
              <i hidden={i === 0} className="bi bi-dot"></i>
              <span>{el}</span>
            </span>
          );
        })}
      </div>
      <div className="col-md-12 recipe-notes" hidden={!props.recipe.notes}>
        <div className="">
          <h2>Notes</h2>
          <p>{props.recipe.notes}</p>
        </div>
      </div>

      <div
        className="col-md-12 text-center recipe-utilities"
        hidden={isEditPage}
      >
        <div hidden={!props.recipe.title}>
          <button
            className="btn m-4 btn-outline-success"
            hidden={props.recipe.approved}
            onClick={() => {
              reviewRecipeBtn(true);
            }}
          >
            - approve -
          </button>
          <button
            className="btn m-4 btn-outline-danger"
            hidden={props.recipe.approved}
            onClick={() => {
              reviewRecipeBtn(false);
            }}
          >
            - disapprove -
          </button>
        </div>
        <div hidden={!props.owned || !props.recipe.approved || showDelete}>
          <button
            className="btn mt-4"
            onClick={() => {
              navigate(`/recipe/${props.recipe._recipeId}/edit`);
            }}
          >
            - edit your recipe -
          </button>
        </div>
        <div hidden={!props.owned || !props.recipe.approved || showDelete}>
          <button className="btn mb-4" onClick={showDeleteRecipe}>
            - delete your recipe -
          </button>
        </div>
        <div
          hidden={!props.owned || !props.recipe.approved || !showDelete}
          className="col-md-6 offset-md-3 mb-5 px-5"
        >
          <div>Are you absolutely sure?</div>
          <div>This action cannot be undone.</div>
          <div className="my-1">
            <input
              className="form-control w-50 d-inline me-2"
              value={confirmDelete}
              onChange={(event: any) => handleChange(event)}
              aria-label="delete confirmation"
              type="text"
              id="delete-confirmation"
              name="delete-confirmation"
              placeholder="type 'delete'"
            />
            <button
              className="me-2 btn"
              onClick={deleteRecipe}
              disabled={confirmDelete.toLowerCase() !== "delete"}
            >
              yes
            </button>

            <button
              className="btn"
              onClick={() => {
                setShowDelete(false);
                setConfirmDelete("");
              }}
            >
              no
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  if (navigation.state === "loading") {
    output = (
      <div className="col-md-12 spinning-loader">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return output;
};

export default RecipeView;
