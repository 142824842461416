import React, { useState, useEffect } from "react";
import "../App.css";

import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  useOutletContext,
  json,
} from "react-router-dom";
import type { TRecipePreview } from "../components/DataTypes";
import SearchView from "../components/search/SearchView";
import { getApiEndpoint } from "../api/endpoints";
import { getToken } from "../utils/authToken";
// import { fakeDelay, generatePreviews } from "../components/Mocks";
import { ITEMS_PER_PAGE } from "../utils/constants";

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    auth,
    setAuth,
    user,
    setUser,
    darkmode,
    setDarkmode,
    nav,
    setNav,
  ]: any = useOutletContext();
  const [pages, setPages] = useState({ current: 1, total: 0 });
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [queryOnLoad, setQueryOnLoad] = useState(null as any);
  const [isInitialMount, setIsInitialMount] = useState(true);
  // const [isInitialPage, setIsInitialMount] = useState(true);
  const [loading, setLoading] = useState(false);

  const [carouselData, setCarouselData]: any = useState({
    ourPicks: [],
    trending: [],
  });

  const handlePageClick = (index: number) => {
    setPages({ ...pages, current: index });
    const params = {
      query: query,
      page: String(index),
    };
    setSearchParams(params);
  };

  // handles "My Recipes" and "Liked Recipe" searches
  const handleMySearch = async () => {
    const page = searchParams.get("page") || "1";
    const params = {
      query: query,
      page: String(page),
    };
    setSearchParams(params);
    const authToken = await getToken();
    // make GET request with search query here
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const url =
        getApiEndpoint("mysearch") +
        query +
        "?page=" +
        ITEMS_PER_PAGE * (+page - 1);
      // ToDo add pagination to query
      const response = await fetch(url, {
        headers,
      });
      const result = await response.json();
      console.warn("query, result = ", result);
      // got page_total here

      setPages({ ...pages, total: result.page_total });
      setResults(result.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("query, Error fetching data:", error);
    }
  };

  const handleSearch = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    if (query === '"my recipes"' || query === '"liked recipes"') {
      handleMySearch();
      return;
    }

    const page = searchParams.get("page") || "1";

    const params = {
      query: query,
      page: String(page),
    };
    setSearchParams(params);
    const authToken = await getToken();
    // make GET request with search query here
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const url =
        getApiEndpoint("search") +
        query +
        "?page=" +
        ITEMS_PER_PAGE * (+page - 1);
      // ToDo add pagination to query
      const response = await fetch(url, {
        headers,
      });
      const result = await response.json();
      console.warn("query, result = ", result);
      setPages({ ...pages, total: result.page_total });
      setResults(result.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("query, Error fetching data:", error);
    }
  };

  function initSearchInputFromUrl() {
    const query = searchParams.get("query");
    const page = searchParams.get("page");
    if (page !== null) {
      setPages({ ...pages, current: +page });
    }
    if (query !== null) {
      setQuery(query);
      setQueryOnLoad(query);
    }
  }

  async function getCarouselData() {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      // ToDo remove hardcode
      const response = await fetch(getApiEndpoint("trending"), {
        headers,
      });
      const result = await response.json();
      setCarouselData({
        ourPicks: result.top,
        trending: result.trending,
      });
    } catch (error) {
      console.error("getCarouselData, Error fetching data:", error);
    }
  }

  // triggers query on load, but skip first render
  // since query value isnt set correctly at that point
  useEffect(() => {
    if (!isInitialMount) {
      const fakeEvent = {
        preventDefault: () => {
          return null;
        },
      };
      handleSearch(fakeEvent);
    } else {
      setIsInitialMount(false);
    }
  }, [queryOnLoad, pages.current]);

  useEffect(() => {
    initSearchInputFromUrl();
    setNav({ ...nav, page: "search" });
    getCarouselData();
  }, []);

  return (
    <SearchView
      results={results}
      auth={auth}
      setAuth={setAuth}
      pageTotal={pages["total"]}
      pageCurrent={pages["current"]}
      onPageClick={handlePageClick}
      handleSearch={handleSearch}
      query={query}
      setQuery={setQuery}
      carouselData={carouselData}
      loading={loading}
    />
  );
}
