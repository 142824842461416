import React from "react";
import "./SyrupLoader.css";
import loadingGif from "../../../assets/images/syrupLoader.gif";

function SyrupLoader(props: any) {
  return (
    <div className={`loading-syrup ` + props.class} hidden={!props.loading}>
      <img src={loadingGif}></img>
    </div>
  );
}

export default SyrupLoader;
