import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer-container mt-auto py-3">
      <a
        className="ig-icon"
        href="https://www.instagram.com/idealpancakes/"
        target="blank"
        aria-label="Ideal pancakes instagram link."
      >
        <i className="bi bi-instagram"></i>
      </a>
      <Link className="" to={`/about-us`}>
        about
      </Link>
      <Link className="" to={`/contact-us`}>
        contact
      </Link>
      <Link className="" to={`/terms-and-conditions`}>
        terms &#38; conditions
      </Link>
      <Link className="" to={`/privacy-policy`}>
        privacy policy
      </Link>
      <i>&copy;{new Date().getFullYear()}</i>
    </footer>
  );
}

export default Footer;
