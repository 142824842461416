import React from "react";
import "./ErrorPage.css";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error: any = useRouteError();
  return (
    <div className="mt-5 row">
      <div className="col-md-12 text-center">
        <h1>Well that did not go well.</h1>
        <p>Apologies, some sort of error has occurred. Most unexpected.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
