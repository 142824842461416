import React, { useState, useEffect } from "react";
import "./Recipe.css";
import type { TRecipe, TTag } from "../DataTypes";
import { useNavigation, useNavigate, Form, Link } from "react-router-dom";
import RecipeView from "./RecipeView";
import { getToken } from "../../utils/authToken";

import { tagsList } from "../Mocks";
import { blankRecipe } from "../DataTypes";
import { userInfo } from "os";
import * as bootstrap from "bootstrap";

import Resizer from "react-image-file-resizer";
import SyrupLoader from "../common/syrupLoader/SyrupLoader";

import {
  MAX_SM_INPUT,
  MAX_MD_INPUT,
  MAX_LG_INPUT,
} from "../../utils/constants";

interface Props {
  recipe: TRecipe;
  user: any;
}

const RecipeEditView = (props: Props) => {
  const navigate = useNavigate();
  const [allTags, setAllTags] = useState(tagsList);
  const [recipe, setRecipe] = useState(props.recipe as TRecipe);
  const [ingredientInput, setIngredientInput] = useState("");
  const [stepInput, setStepInput] = useState("");
  const tagLimit = 4;
  const [errors, setErrors] = useState({} as any);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  // Figure out if we are creating new or editing existing
  const parts = location.pathname.split("/");
  const recipeID = parts[2];
  const isNewRecipe = recipeID.includes("-1");
  const [loading, setLoading] = useState(false as boolean);

  useEffect(() => {
    setRecipe(props.recipe);
  }, [props.recipe]);

  // generic change function for inputs. Note: uses attr name to find key for value
  // and boolean to handle number changes
  const handleChange = (event: any, isNumber: boolean) => {
    const name = event.target.name;
    let value = event.target.value;
    if (isNumber) {
      value = Number(event.target.value);
    }
    const maxServings = 100;
    if (name === "servings" && value > maxServings) {
      value = maxServings;
    }
    setRecipe({ ...recipe, [name]: value });
  };

  // handle prep + cook time conversions
  const handleChangeCookTime = (event: any, isMinutes: boolean) => {
    const current = recipe.cookTime.split(":");
    let newTime = "";
    let value = event.target.value;
    if (isMinutes) {
      if (event.target.value > 60) {
        value = 59;
      } else if (event.target.value < 0) {
        value = 0;
      }
      newTime = current[0] + ":" + value;
    } else {
      if (event.target.value > 48) {
        value = 48;
      } else if (event.target.value < 0) {
        value = 0;
      }
      newTime = value + ":" + current[1];
    }
    setRecipe({ ...recipe, cookTime: newTime });
  };

  // handles add/remove of tags on recipe
  const handleChangeTag = (event: any) => {
    const text: string = event.target.innerText;
    if (recipe.tags.length < tagLimit) {
      recipe.tags.push(text);
      setAllTags(allTags.filter((item: any) => item !== text));
      setRecipe({ ...recipe, tags: recipe.tags });
    }
  };
  const viewTags = allTags.map((tag: string, i: number) => {
    return (
      <div key={"possibleTag-" + i} className="category-tag d-inline-block">
        <span
          className="text-nowrap"
          onClick={(event: any) => handleChangeTag(event)}
        >
          {tag}
        </span>
      </div>
    );
  });

  const handleListChange = (event: any, inputField: string) => {
    if (inputField === "step") {
      setStepInput(event.target.value);
    } else if (inputField === "ingredient") {
      setIngredientInput(event.target.value);
    }
  };

  const handleListRemove = (index: number, listName: string) => {
    // if its a tag i need to add it back to the list
    if (listName === "tags") {
      allTags.push(recipe.tags[index]);
      allTags.sort();
      setAllTags(allTags);
    }
    (recipe as any)[listName].splice(index, 1);
    setRecipe({ ...recipe, [listName]: (recipe as any)[listName] });
  };

  const handleListAdd = (inputField: string) => {
    const listLimit = 50;
    if (inputField === "step" && stepInput) {
      if (recipe.steps.length >= listLimit) {
        return;
      }
      // can use ~ for now to deliniate steps in one text string
      if (stepInput.includes("~")) {
        const multiInput = stepInput.split("~");
        multiInput.forEach((step) => {
          recipe.steps.push(step);
        });
      } else {
        recipe.steps.push(stepInput);
      }
      setRecipe({ ...recipe, steps: recipe.steps });
      setStepInput("");
    } else if (inputField === "ingredient" && ingredientInput) {
      if (recipe.ingredients.length >= listLimit) {
        return;
      }
      if (ingredientInput.includes("~")) {
        const multi = ingredientInput.split("~");
        multi.forEach((ingredient) => {
          recipe.ingredients.push(ingredient);
        });
      } else {
        recipe.ingredients.push(ingredientInput);
      }
      setRecipe({ ...recipe, ingredients: recipe.ingredients });
      setIngredientInput("");
    }
  };

  // handle upload image preview and onChange function to set value
  const [uploadedFileName, setUploadedFileName]: [string, any] = useState("");

  const readURL = async (input: any) => {
    if (input.target.files && input.target.files[0]) {
      // do resize here?
      const file = input.target.files[0];
      const fileSize = input.target.files[0].size;
      console.warn(
        "readURL: user input FILE SIZE in MB",
        (fileSize / (1024 * 1024)).toFixed(2)
      );

      const resizedImage: any = await resizeFile(file);
      setRecipe({ ...recipe, img: resizedImage });

      // save old here - working
      // setUploadedFileName(input.target.files[0].name);
      // const reader = new FileReader();
      // reader.onload = function (e) {
      //   if (e.target !== null) {
      //     setRecipe({ ...recipe, img: String(e.target.result) });
      //   }
      // };
      // reader.readAsDataURL(input.target.files[0]);
    }
  };

  // USAGE
  // Resizer.imageFileResizer(
  //   file, // Is the file of the image which will resized.
  //   maxWidth, // Is the maxWidth of the resized new image.
  //   maxHeight, // Is the maxHeight of the resized new image.
  //   compressFormat, // Is the compressFormat of the resized new image.
  //   quality, // Is the quality of the resized new image.
  //   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  //   responseUriFunc, // Is the callBack function of the resized new image URI.
  //   outputType, // Is the output type of the resized new image.
  //   minWidth, // Is the minWidth of the resized new image.
  //   minHeight // Is the minHeight of the resized new image.
  // );
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      const quality = 85;
      Resizer.imageFileResizer(
        file,
        2000,
        2000,
        "JPEG",
        quality,
        0,
        (uri) => {
          // Type assertion to let TypeScript know it's a Blob
          const blob = uri as Blob;

          // Create a new File object with the resized content
          const newFile = new File([blob], file.name, {
            type: "image/jpeg",
          });

          // Get the size of the resized file
          const newSizeInBytes = newFile.size;
          const newSizeInMB = (newSizeInBytes / (1024 * 1024)).toFixed(2);

          console.warn(`Resized file size: ${newSizeInMB} MB`);
          console.warn("quality = ", quality);

          resolve(uri);
        },
        "base64"
      );
    });

  const handleSubmit = async (event: any) => {
    // need to do a PUT instead of a POST when editing, but how to tell?
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Perform form submission
      try {
        const authToken = await getToken();
        let urlPath = "https://api.idealpancakes.com/recipe";
        let method = "POST";
        if (!isNewRecipe) {
          method = "PUT";
          urlPath = urlPath + "/" + recipe._recipeId;
        }
        const response = await fetch(urlPath, {
          method: method,
          body: JSON.stringify(recipe),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Server rejected the request");
        }
        // Successful submission, you can clear the form or navigate to a success page
        setSubmitSuccess(true);
        setRecipe(blankRecipe);
        setLoading(false);
        setErrors({});
      } catch (error) {
        // Error message for server rejection
        setSubmitError(true);
        setSubmitSuccess(false);
        setLoading(false);
        setErrors({
          serverError:
            "shut the front door - Server rejected the request. Please try again.",
        });
      }
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!recipe.title) {
      newErrors.title = "title is required";
    }
    if (recipe.servings < 1) {
      newErrors.servings = "servings greater than 1 is required";
    }
    if (recipe.cookTime === "00:00") {
      newErrors.cookTime = "cook time is required";
    }

    if (!recipe.img) {
      newErrors.img = "image is required";
    }
    if (!recipe.description) {
      newErrors.description = "description is required";
    }
    if (!recipe.ingredients.length) {
      newErrors.ingredients = "ingredients are required";
    }
    if (!recipe.steps.length) {
      newErrors.steps = "steps are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // adds step or ingredient on 'enter'
  const handleKeyDown = (event: any, inputField: string) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleListAdd(inputField);
    }
  };

  return (
    <div>
      <SyrupLoader loading={loading} />
      <div
        className="col-md-12 px-2 mt-1"
        hidden={submitSuccess || submitError || loading}
      >
        <h1>Create Recipe</h1>
        <Form className="row" onSubmit={handleSubmit}>
          <div className="col-md-4 pb-3 recipe-form">
            <div className="">
              <label htmlFor="inputTitle" className="form-label">
                Title {errors.title && <span className="error">*</span>}
              </label>
              <input
                value={recipe.title}
                onChange={(event: any) => handleChange(event, false)}
                aria-label="title"
                type="text"
                id="title"
                name="title"
                className="form-control"
                spellCheck={true}
                maxLength={MAX_SM_INPUT}
                onKeyDown={(event: any) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSubmit(event);
                  }
                }}
              />
              {errors.title && <span className="error">{errors.title}</span>}
            </div>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="inputTitle" className="form-label">
                  Combined Prep &amp; Cook Time{" "}
                  {errors.cookTime && <span className="error">*</span>}
                </label>
                <div className="input-group">
                  <input
                    value={parseInt(recipe.cookTime.split(":")[0])}
                    onChange={(event: any) =>
                      handleChangeCookTime(event, false)
                    }
                    aria-label="cook time hours"
                    type="number"
                    name="cookTimeHours"
                    className="form-control"
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSubmit(event);
                      }
                    }}
                  />
                  <span className="input-group-text">:</span>
                  <input
                    value={parseInt(recipe.cookTime.split(":")[1])}
                    onChange={(event: any) => handleChangeCookTime(event, true)}
                    aria-label="cook time minutes"
                    type="number"
                    name="cookTimeMinutes"
                    className="form-control"
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSubmit(event);
                      }
                    }}
                  />
                </div>
                {errors.cookTime && (
                  <span className="error">{errors.cookTime}</span>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="inputServings" className="form-label">
                  Servings {errors.servings && <span className="error">*</span>}
                </label>
                <input
                  value={String(recipe.servings)}
                  onChange={(event: any) => handleChange(event, true)}
                  aria-label="servings"
                  type="number"
                  id="servings"
                  name="servings"
                  className="form-control w-50"
                  onKeyDown={(event: any) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSubmit(event);
                    }
                  }}
                />
                {errors.servings && (
                  <span className="error">{errors.servings}</span>
                )}
              </div>
            </div>
            <div>
              {/* Upload image input */}
              <label htmlFor="upload" className="form-label">
                Photo Upload <span className="text-muted">(.jpeg or .png)</span>
                {errors.img && <span className="error">*</span>}
              </label>
              <div className="input-group">
                <input
                  id="upload"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(el) => {
                    // posible to resize here?
                    readURL(el);
                  }}
                  className="form-control border-0"
                />
                <label
                  id="upload-label"
                  htmlFor="upload"
                  className="font-weight-light text-muted"
                ></label>
                <div className="input-group-append">
                  <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                </div>
              </div>
              {errors.img && <span className="error">{errors.img}</span>}
            </div>
            <div className="">
              <label htmlFor="descriptionTextarea" className="form-label">
                Description{" "}
                {errors.description && <span className="error">*</span>}
              </label>

              <div className="input-group">
                <textarea
                  value={recipe.description}
                  onChange={(event: any) => handleChange(event, false)}
                  className="form-control"
                  aria-label="description text area"
                  id="description"
                  name="description"
                  spellCheck={true}
                  maxLength={MAX_LG_INPUT}
                ></textarea>
              </div>
              {errors.description && (
                <span className="error">{errors.description}</span>
              )}
            </div>

            <div className="">
              <div className="mt-4">
                <label htmlFor="inputTitle" className="form-label">
                  Ingredients{" "}
                  {errors.ingredients && <span className="error">*</span>}
                </label>
                <div className="input-group mb-3">
                  <input
                    value={ingredientInput}
                    onChange={(event: any) =>
                      handleListChange(event, "ingredient")
                    }
                    type="text"
                    className="form-control"
                    id="ingredientRaw"
                    name="ingredientRaw"
                    aria-label="ingredient"
                    aria-describedby="button-add-ingredient"
                    onKeyDown={(event: any) =>
                      handleKeyDown(event, "ingredient")
                    }
                    spellCheck={true}
                    maxLength={MAX_MD_INPUT}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-add-ingredient"
                    onClick={() => handleListAdd("ingredient")}
                  >
                    Add
                  </button>
                </div>
                {errors.ingredients && (
                  <span className="error">{errors.ingredients}</span>
                )}
              </div>

              <div className="">
                <label htmlFor="instructionsTextarea" className="form-label">
                  Steps {errors.steps && <span className="error">*</span>}
                </label>
                <div className="input-group">
                  <textarea
                    value={stepInput}
                    onChange={(event: any) => handleListChange(event, "step")}
                    className="form-control"
                    aria-label="instruction text area"
                    aria-describedby="button-add-step"
                    onKeyDown={(event: any) => handleKeyDown(event, "step")}
                    spellCheck={true}
                    maxLength={MAX_MD_INPUT}
                  ></textarea>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-add-step"
                    onClick={() => handleListAdd("step")}
                  >
                    Add
                  </button>
                </div>
                {errors.steps && <span className="error">{errors.steps}</span>}
              </div>
            </div>
            <div className="">
              <label htmlFor="inputTitle" className="form-label">
                Choose up to {tagLimit} tags to help people find your recipe!
              </label>
              <div className="overflow-scroll tag-window">{viewTags}</div>
            </div>
            <div className="">
              <label htmlFor="notesTextarea" className="form-label">
                Notes
              </label>

              <div className="input-group">
                <textarea
                  value={recipe.notes}
                  onChange={(event: any) => handleChange(event, false)}
                  className="form-control"
                  aria-label="notes text area"
                  id="notes"
                  name="notes"
                  spellCheck={true}
                  maxLength={MAX_LG_INPUT}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-md-8 edit-recipe-card">
            <RecipeView
              recipe={recipe}
              handleListRemove={handleListRemove}
              owned={false}
              userID={props.user._userId}
            />
          </div>

          <div className="row p-5">
            {/* show different message if editing */}

            <div className="col-md-6">
              {/*  approve or disaprove buttons go here*/}
              {/* <button></button> */}
              <button type="submit" className="btn submit-recipe-btn">
                Submit <span hidden={isNewRecipe}>Edits</span> for Review
              </button>
            </div>
          </div>
        </Form>
      </div>
      {(submitSuccess || submitError) && (
        <div className="row">
          <div className="col-md-6 offset-md-3 mt-5">
            <div className="p-4 m-4 edit-recipe-card">
              {submitSuccess && (
                <div>
                  <h2 className="text-start">Success!</h2>
                  <p>
                    Thank you for contributing to the the Ideal Pancakes
                    Library, your <span hidden={isNewRecipe}>edits</span>{" "}
                    <span hidden={!isNewRecipe}>recipe</span> will be reviewed
                    and published soon!
                  </p>
                </div>
              )}

              {submitError && (
                <div>
                  <h2 className="text-start">Oops!</h2>
                  <p>
                    Something went wrong while submitting your recipe. Please
                    try again later.
                  </p>
                </div>
              )}

              <button
                className="btn"
                onClick={() => {
                  setSubmitSuccess(false);
                  setSubmitError(false);
                }}
              >
                - back -
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RecipeEditView;
