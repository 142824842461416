import React from "react";
import "./aboutUs.css";

import ipImage from "../../assets/images/pattern-IP.png";

// interface Props {}
const AboutUsView = (props: any) => {
  return (
    <div className="col-md-6 offset-md-3">
      <div className="m-5">
        <h1 className="mb-4">About Us</h1>
        <p>
          Ideal Pancakes is a project born out of equal parts love and
          frustration — a love for both cooking and eating, combined with the
          frustration of endless scrolling and skimming through unnecessary
          backstories and obnoxious ads while simply trying to access a
          straightforward recipe on the internet.
        </p>
        <p>
          Our goal is to empower food enthusiasts and home chefs with a
          streamlined recipe discovery and cooking experience, ensuring that
          every culinary endeavor is a pleasurable and stress-free journey.
        </p>
        <p>Thank you for being a part of our community.</p>
        <p>Happy Cooking!</p>

        <div className="ig-call-to-action">
          <span>Follow us on Instagram!</span>
          <a
            href="https://www.instagram.com/idealpancakes/"
            target="blank"
            aria-label="A link to ideal pancakes instagram."
          >
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>

      <img className="logo-pattern-img" src={ipImage} alt=""></img>
    </div>
  );
};

export default AboutUsView;
