async function generateRecaptchaToken(action: string) {
  try {
    const token = await (window as any).grecaptcha.enterprise.execute(
      "6LfpDWYpAAAAAIXxFPj5mndRQcW87sxRXJGs-mz6",
      {
        action: action,
      }
    );
    return token;
  } catch (error) {
    // Handle any errors that might occur during the execution
    console.error("Error executing reCAPTCHA:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

export async function getRecaptchaScore(action: string) {
  // actions: signup | login | forgot_password
  const token = await generateRecaptchaToken(action);
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const postData = {
      token: token,
      expectedAction: action,
    };
    const response: any = await fetch(
      "https://api.idealpancakes.com/recaptcha",
      {
        method: "POST",
        headers,
        body: JSON.stringify(postData),
      }
    );
    // response should be between 0 and 1
    const result = await response.json();
    const score = result.score;
    console.warn("reCAPTCHA: Score = ", score);
    if (score && score > 0.5) {
      console.warn("reCAPTCHA -" + action + "- YOU ARE HUMAN!!");
      return true;
    } else {
      console.warn("reCAPTCHA -" + action + "- YOU ARE ROBOT!!");
      return false;
    }
  } catch (error) {
    console.error("Error making recaptcha POST request:", error);
  }
}
