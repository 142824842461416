// TypeScript Data Structures
// NOTE: using "_" prefix to denote rough "private" variables

// TRecipePreview describes a single, summarized, recipe object. Returned from
// search queries and used to display results.
export type TRecipePreview = {
  _recipeId: string;
  title: string;
  author: string;
  img: string;
};

// TRecipe describes a complete recipe object, including all sub-types.
export type TRecipe = {
  _recipeId: string;
  _userId: string;
  author: string;
  title: string;
  description: string;
  img: string;
  cookTime: string;
  servings: number;
  tags: string[];
  ingredients: string[];
  steps: string[];
  notes?: string;
  likes: number;
  approved?: boolean;
};
export const blankRecipe: TRecipe = {
  _recipeId: "0",
  _userId: "",
  author: "",
  title: "",
  description: "",
  img: "",
  cookTime: "00:00",
  servings: 4,
  tags: [],
  ingredients: [],
  steps: [],
  notes: "",
  likes: 0,
};

// TTag describes a tag object, including properties such as color. Used only
// by the full list of backend tags. Only tag names are stored with recipe.
export type TTag = {
  name: string;
  color: string;
};

// TUser describes a single authenticated user object which holds all mapping
// data and profile settings.
export type TUser = {
  _userId: string; // set by cognito, unchangeable
  username: string; // set by cognito, unchangeable
  _token: string; // returned by cognito, stored only in users browser and used for authenticated backend calls
  author: string; // visible name, changeable
  dateLastLogin: string;
  dateUserCreated: string;
  statRecipesMaintained: number;
};

// TLoginData is a helper schema on the frontend only to simplify passing login data between profile forms.
export type TLoginData = {
  username: string;
  password: string;
  newLoginPassword: string;
  oldPassword: string;
  newPassword: string;
};
// TNavData is a helper schema on the frontend only that holds the data needed to determine what buttons to present
// the user in the navbar and properly display the actions (ie recipeTitle).
export type TNavData = {
  page: string;
  owned: boolean;
  liked: boolean;
  _recipeId: string;
  recipeTitle: string;
};
