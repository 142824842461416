const basePath = "https://api.idealpancakes.com";
const apiEndpoints: any = {
  profile: "/profile",
  recpie: "/recipe",
  contact: "/contact",
  search: "/search/",
  mysearch: "/mysearch/",
  trending: "/trending",
};

export function getApiEndpoint(key: string) {
  let wholePath = "";
  if (apiEndpoints[key]) {
    wholePath = basePath + apiEndpoints[key];
  } else {
    console.warn("no endpoint found matching = ", key);
  }
  return new URL(wholePath);
}
