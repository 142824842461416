import React, { useEffect, useState } from "react";
import {
  useOutletContext,
  json,
  useLoaderData,
  redirect,
  useParams,
  useNavigate,
} from "react-router-dom";

import RecipeEditView from "../components/recipe/RecipeEditView";
import type { TRecipe } from "../components/DataTypes";
import { blankRecipe } from "../components/DataTypes";
import { getToken } from "../utils/authToken";

export default function RecipeEdit() {
  const [
    auth,
    setAuth,
    user,
    setUser,
    darkmode,
    setDarkmode,
    nav,
    setNav,
    setError,
  ]: any = useOutletContext();

  const navigate = useNavigate();
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(blankRecipe);

  const getRecipe = async function (recipeId: any) {
    const authToken = await getToken();
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json", // Adjust content type if needed
      };

      const response = await fetch(
        "https://api.idealpancakes.com/recipe/" + recipeId,
        {
          headers,
        }
      );

      const result = await response.json();
      // console.warn("get recipe data, result = ", result);
      // do nothing if author dosnt match
      if (result.author !== user.author) {
        console.warn("HEY IT DOEST LOOK LIKE YOU OWN THIS RECIPE");
        return;
      }
      setRecipe(result);
    } catch (error) {
      // ToDo: if error or no profile found, boot them back to login page
      console.error("get recipe data, Error fetching data:", error);
    }
  };

  useEffect(() => {
    // if no user signed in redirect to sign in page
    const checkForUser = async function () {
      const authToken = await getToken();
      if (authToken === "undefined") {
        // NO USER FOUND
        const currentLocation = window.location.pathname;
        if (!currentLocation.includes("/profile"))
          navigate("/profile?redirect=" + currentLocation, {
            state: { from: currentLocation },
          });
      } else {
        // Edit Recipe - recipe id found so GET recipe
        if (recipeId && recipeId.toString() !== "-1") {
          getRecipe(recipeId);
        } else {
          // New Recipe - so set blank form with author
          setRecipe({
            ...recipe,
            author: user.author,
          });
        }
      }
    };
    checkForUser();

    // setNav({ ...nav, page: "recipeEdit", _recipeId: recipe._recipeId });
  }, [user]);

  return (
    <div className="row">
      <RecipeEditView user={user} recipe={recipe} />
    </div>
  );
}
