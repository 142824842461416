import React, { useEffect, useState } from "react";
import "./SearchView.css";
import { useNavigation, useNavigate, Form } from "react-router-dom";

import RecipePreview from "../recipe/RecipePreview";
import type { TRecipePreview } from "../DataTypes";
import landingImage from "../../assets/images/logo-tranparent-idealpancakes.png";
import whiteLandingImage from "../../assets/images/white-IP-Logo.png";
import bannerImg from "../../assets/images/banner-img.jpg";
import SyrupLoader from "../common/syrupLoader/SyrupLoader";
import { ITEMS_PER_PAGE } from "../../utils/constants";

interface Props {
  results: TRecipePreview[];
  auth: boolean;
  setAuth: any;
  pageTotal: number;
  pageCurrent: number;
  onPageClick: any;
  handleSearch: any;
  query: string;
  setQuery: any;
  carouselData: any;
  loading: boolean;
}

const SearchBar = (props: any) => (
  <div className={props.classArg}>
    <Form>
      <input
        value={props.query}
        onChange={(event: any) => {
          props.setQuery(event.target.value);
        }}
        type="search"
        name="search"
        id="search"
        className="form-control"
        placeholder="Find a Recipe"
        aria-label="Search Recipes"
        autoComplete="off"
      />
      <button
        className="btn btn-secondary"
        disabled={props.loading}
        onClick={props.handleSearch}
      >
        Search
      </button>
    </Form>
  </div>
);

function Carousel(props: any) {
  const navigate = useNavigate();
  const itemsPerPage = 4;
  const only4Items = itemsPerPage === props.items.length;
  const numSections = Math.floor(props.items.length / itemsPerPage); // round sections to floor so each section is full
  const sectionsAry = [];
  let count = 0;
  for (let i = 0; i < numSections; i++) {
    const items = [];
    for (let idx = count; idx < count + itemsPerPage; idx++) {
      items.push(props.items[idx]);
    }
    count = count + itemsPerPage;

    sectionsAry.push({ idx: i, items: items });
  }
  return (
    <div id={props.id} className="carousel slide row" data-interval="false">
      <div className="carousel-inner">
        {sectionsAry.map((item: any, index: any) => {
          return (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <div className="">
                {item.items.map((item: any, index: any) => {
                  return (
                    <div
                      className="carousel-sub-item"
                      key={"item" + index}
                      onClick={() => {
                        // Navigate to recipe
                        navigate("/recipe/" + item._recipeId);
                      }}
                    >
                      <div className="img-div">
                        <img
                          src={item.img}
                          className="d-block w-100 recipe-img"
                          alt={item.title}
                        />
                      </div>

                      <div className="carousel-hover-text">
                        <div className="carousel-title">{item.title}</div>
                        <div className="carousel-author">by {item.author}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <button
        hidden={only4Items}
        className={`carousel-control carousel-control-prev ${
          props.darkmode === "true" ? "dark-mode" : ""
        }`}
        type="button"
        data-bs-target={"#" + props.id}
        data-bs-slide="prev"
      >
        <i className="bi bi-caret-left-fill" aria-hidden="true"></i>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        hidden={only4Items}
        className={`carousel-control carousel-control-next ${
          props.darkmode === "true" ? "dark-mode" : ""
        }`}
        type="button"
        data-bs-target={"#" + props.id}
        data-bs-slide="next"
      >
        <i className="bi bi-caret-right-fill" aria-hidden="true"></i>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

const SearchView = (props: Props) => {
  const navigation = useNavigation();
  const darkmode: any = localStorage.getItem("idealpancakes.darkmode");
  const [landingImgSrc, setLandingImgSrc]: any = useState(landingImage);

  useEffect(() => {
    if (darkmode === "true") {
      setLandingImgSrc(whiteLandingImage);
    } else {
      setLandingImgSrc(landingImage);
    }
  }, []);
  // -- define bootstrap pagination bar --
  const options = [...Array(Math.ceil(props.pageTotal / ITEMS_PER_PAGE))].map(
    (_, i) => {
      const adjustedIndex = i + 1;
      const pageStyle = adjustedIndex === props.pageCurrent ? "disabled" : "";
      if (adjustedIndex === 0 || adjustedIndex === props.pageTotal) {
        // first page and page after last
        return null;
      }
      return (
        <li
          key={i}
          className="page-item"
          onClick={() => {
            if (adjustedIndex !== props.pageCurrent)
              props.onPageClick(adjustedIndex);
          }}
        >
          <div className={"page-link " + pageStyle}>{adjustedIndex}</div>
        </li>
      );
    }
  );
  const startPageStyle = props.pageCurrent === 1 ? "disabled" : "";
  const endPageStyle =
    props.pageCurrent === Math.ceil(props.pageTotal / ITEMS_PER_PAGE)
      ? "disabled"
      : "";
  const paginationBarView = (
    <div
      className="row mb-4"
      hidden={
        navigation.state === "loading" || props.pageTotal <= ITEMS_PER_PAGE
      }
    >
      <nav aria-label="Search results pages">
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <div
              className={"page-link " + startPageStyle}
              aria-label="Previous"
              onClick={() => {
                if (props.pageCurrent > 1) props.onPageClick(1);
              }}
            >
              <span aria-hidden="true">&laquo;</span>
            </div>
          </li>
          {options}
          <li className="page-item">
            <div
              className={"page-link " + endPageStyle}
              aria-label="Next"
              onClick={() => {
                if (props.pageCurrent < props.pageTotal - 1)
                  props.onPageClick(
                    Math.ceil(props.pageTotal / ITEMS_PER_PAGE)
                  );
              }}
            >
              <span aria-hidden="true">&raquo;</span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );

  // -- format recipe previews for results --
  const resultsList = props.results.map((item: TRecipePreview, i) => {
    return (
      <div key={i + item._recipeId} className="col-xl-4 col-lg-6">
        <RecipePreview
          title={item.title}
          id={item._recipeId}
          author={item.author}
          img={item.img}
        />
      </div>
    );
  });
  const noResultsView = (
    <div className="col-sm-12 text-center">No results found.</div>
  );

  let resultsView =
    resultsList !== undefined && resultsList.length > 0
      ? resultsList
      : noResultsView;
  if (navigation.state === "loading") {
    resultsView = (
      <div className="col-md-12 spinning-loader">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const variableView = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const query = queryParameters.get("query");
    if (query === null) {
      return (
        <div>
          <div className="row landing-page">
            <div className="col-md-7 text-center">
              <img className="mx-auto" src={landingImgSrc} alt="" />
            </div>
            <div className="col-md-5 landing-page-text">
              <h1>Welcome!</h1>
              <p>
                What would you <br />
                like to cook?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <SearchBar
                classArg={`search-bar ${
                  darkmode === "true" ? "dark-mode" : ""
                }`}
                query={props.query}
                setQuery={props.setQuery}
                loading={props.loading}
                handleSearch={props.handleSearch}
              />
            </div>
          </div>

          <div className="carousel-container row">
            <div
              className="col-md-10 offset-md-1"
              hidden={props.carouselData["ourPicks"].length === 0}
            >
              <h1>Our Picks</h1>
              <Carousel
                items={props.carouselData["ourPicks"]}
                id="our-picks"
                darkmode={darkmode}
              />
            </div>
          </div>
          <div className="carousel-container row">
            <div
              className="col-md-10 offset-md-1"
              hidden={props.carouselData["trending"].length === 0}
            >
              <h1>Trending</h1>
              <Carousel
                items={props.carouselData["trending"]}
                id="trending"
                darkmode={darkmode}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-12 search-results-container">
          <img className="banner-image" src={bannerImg} alt=""></img>
          <SearchBar
            classArg="search-input"
            query={props.query}
            setQuery={props.setQuery}
            loading={props.loading}
            handleSearch={props.handleSearch}
          />
          <SyrupLoader loading={props.loading} class="mini" />
          <div hidden={props.loading}>
            <div className="search-results row">{resultsView}</div>
            <div>{paginationBarView}</div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="">
      {/* // show different results view once user has clicked search */}
      {variableView()}
    </div>
  );
};
export default SearchView;
