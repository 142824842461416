import React, { useState } from "react";
import {
  signUp,
  confirmSignUp,
  autoSignIn,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import "./recoverPassword.css";
import { passwordValidator, emailValidator } from "../../utils/validation";
import type { TUser, TLoginData } from "../DataTypes";
import { getToken } from "../../utils/authToken";
import { Link, useNavigate } from "react-router-dom";
import SyrupLoader from "../../components/common/syrupLoader/SyrupLoader";
import { getRecaptchaScore } from "../../utils/reCaptcha";

const PasswordValidatorIcons = (props: any) => {
  return (
    <div className="validator-icons">
      <i
        hidden={passwordValidator(props.value, props.condition)}
        className="bi bi-x-circle"
      ></i>
      <i
        hidden={!passwordValidator(props.value, props.condition)}
        className={`bi bi-check2-circle${
          passwordValidator(props.value, props.condition) ? " valid" : ""
        }`}
      ></i>
    </div>
  );
};
const PasswordHelp = (props: any) => {
  return (
    <div id="passwordHelp" className="password-help">
      <div>
        <PasswordValidatorIcons condition={1} value={props.value} />
        <span> minimum 8 characters</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={2} value={props.value} />
        <span> at least one upper and lower character</span>
      </div>
      <div>
        <PasswordValidatorIcons condition={3} value={props.value} />
        <span> at least one special character</span>
      </div>
    </div>
  );
};
interface Props {
  user: TUser;
  auth: boolean;
  darkmode: boolean;
  setDarkmode: any;
  setAuth: any;
  setUser: any;
}
const RecoverPasswordPage: any = (props: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    newPassword: "",

    confirmationCode: "",
  });
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const [loading, setLoading] = useState(false as boolean);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // second one
  const handleSubmitConfirmation = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    setLoading(true);

    event.preventDefault();
    const username = formData.email;
    const confirmationCode = formData.confirmationCode;
    console.log("handleSubmitConfirmation: code =", formData.confirmationCode);

    const newPassword = formData.newPassword;
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      setLoading(false);
      navigate("/profile");
    } catch (error) {
      console.log(error);
      setErrorSubmission(true);
      setLoading(false);
    }
  };

  // first one
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // validate first
    if (emailValidator(formData.email)) {
      setLoading(true);
      // then reCaptcha
      const isHuman = await getRecaptchaScore("forgot_password");
      if (isHuman) {
        const username = formData.email;
        try {
          const output = await resetPassword({ username });
          console.warn;
          // handleResetPasswordNextSteps(output);
          setSuccessfulSubmission(true);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setErrorSubmission(true);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setErrorSubmission(true);
      }
    }
  };

  // show success msg and check for email confirmation after form submit
  if (successfulSubmission) {
    // success email confirmation message
    return (
      <div className="col-md-6 offset-md-3 mt-5 recover-password-container">
        <SyrupLoader loading={loading} />
        <div className="welcome-message" hidden={loading}>
          <h2>Recover Password</h2>

          <p>
            Please check your email for a confirmation code and enter it below
            along with your new password.
          </p>
          <form onSubmit={handleSubmitConfirmation}>
            <div className="mb-3">
              <label htmlFor="confirmationCode" className="form-label">
                confirmation code
              </label>
              {/* <input type="email" className="form-control" /> */}
              <input
                type="text"
                id="confirmationCode"
                name="confirmationCode"
                value={formData.confirmationCode}
                onChange={handleInputChange}
                className="form-control"
                autoComplete="do-not-autofill"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                new password
              </label>
              <input
                type="text"
                id="newPassword"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                className={`form-control${
                  passwordValidator(formData.newPassword, 0)
                    ? " valid"
                    : " invalid"
                }`}
              />
              <PasswordHelp value={formData.newPassword} />
            </div>
            <button type="submit" className="btn btn-primary">
              Confirm
            </button>
          </form>
          <div className="text-center">
            <p
              className="btn nav-btn"
              onClick={() => {
                setSuccessfulSubmission(false);
              }}
            >
              - go back -
            </p>
          </div>
        </div>
      </div>
    );
  } else if (errorSubmission) {
    return (
      <div className="col-md-6 offset-md-3 mt-5 sign-up-container">
        <p>Uh oh, something went wrong, please try again at a later time.</p>
      </div>
    );
  } else {
    // show recover password form
    return (
      <div className="col-md-4 offset-md-4 mt-5 recover-password-container">
        <SyrupLoader loading={loading} />
        <div className="" hidden={loading}>
          <h2>Recover Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label htmlFor="email" className="form-label">
                email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`form-control${
                  emailValidator(formData.email) ? " valid" : " invalid"
                }`}
              />
            </div>
            {/* reCaptcha - required to display in workflow */}
            <div className="text-muted recaptcha-text m-1">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
              apply.
            </div>
            {/* END reCaptcha - required to display in workflow */}

            <div className="text-center mt-2">
              <button type="submit" className="btn btn-primary w-100">
                Send Confirmation Code
              </button>
              <div className="mt-4">
                <Link to="/profile" className="btn nav-btn">
                  go back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default RecoverPasswordPage;
